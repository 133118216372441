import { useSelector } from "react-redux";
import { selectCurrentToken } from "../app/api/auth/authSlice";
import { jwtDecode } from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isManager = false;
  let isAdmin = false;
  let status = 10001;

  if (token) {
    const decoded = jwtDecode(token);
    const { username, roles, image_logo, branch_name, branch_no, id } =
      decoded.UserInfo;

    isManager = roles.includes(1001);
    isAdmin = roles.includes(2001);

    if (isManager) status = "Manager";
    if (isAdmin) status = "Admin";
    return {
      username,
      roles,
      status,
      isManager,
      isAdmin,
      image_logo,
      branch_name,
      branch_no,
      id,
    };
  }

  return {
    username: "",
    roles: [],
    isManager,
    isAdmin,
    status,
    branch_no: "",
    id: "",
  };
};
export default useAuth;
