import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import { Typography } from "@mui/material";
import router from "../../utils/pageRoutes";

function iconSet(title, selected) {
  let color = selected ? "#FF5F00" : "inherit";
  switch (title) {
    case "Dashboard":
      return <DashboardIcon sx={{ color: color }} />;
    case "Administration":
      return <SupervisorAccountIcon sx={{ color: color }} />;
    case "Employee data management":
      return <AdminPanelSettingsIcon sx={{ color: color }} />;
    case "Time & attendance management":
      return <PendingActionsIcon sx={{ color: color }} />;
    case "Payroll processing":
      return <PointOfSaleIcon sx={{ color: color }} />;
    case "Reporting and analytics":
      return <AnalyticsIcon sx={{ color: color }} />;
    default:
      return <InboxIcon sx={{ color: color }} />;
  }
}

const SideNavGen = ({ openStateData }) => {
  const { roles } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [openSubmenus, setOpenSubmenus] = useState({});

  const filterRoutes = (routes, roles) => {
    return routes
      .map((route) => {
        if (route.items) {
          const filteredItems = filterRoutes(route.items, roles);
          if (filteredItems.length > 0) {
            return { ...route, items: filteredItems };
          }
          return null;
        }
        if (
          route.element.props.allowedRoles?.some((role) => roles.includes(role))
        ) {
          return route;
        }
        return null;
      })
      .filter((route) => route !== null);
  };

  const allowedRoutes = filterRoutes(router, roles);

  const handleToggle = (index) => {
    setOpenSubmenus((prevOpenSubmenus) => ({
      ...prevOpenSubmenus,
      [index]: !prevOpenSubmenus[index],
    }));
  };

  return (
    <List sx={{ paddingTop: "0px !important;" }}>
      {allowedRoutes.map((route, index) => {
        const selected = location.pathname === route.path;
        const isOpen = openSubmenus[index] || false;

        return (
          <React.Fragment key={index}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              style={{ display: route.isHidden ? "none" : "block" }}
              onClick={() => {
                if (route.items && route.items.length > 0) {
                  handleToggle(index);
                } else {
                  navigate(route.path);
                }
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: openStateData ? "initial" : "center",
                  px: 2.5,
                  backgroundColor: selected ? "rgb(255, 196, 54)" : "inherit",
                  "&:hover": {
                    backgroundColor: "rgb(255, 196, 54)",
                  },
                }}
                className="testitem"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openStateData ? 3 : "auto",
                    justifyContent: "center",
                    color: selected ? "#FF5F00" : "inherit",
                  }}
                >
                  {iconSet(route.title, selected)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      noWrap
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: openStateData ? "normal" : "nowrap",
                        fontWeight: 700,
                      }}
                    >
                      {route.title}
                    </Typography>
                  }
                  sx={{
                    opacity: openStateData ? 1 : 0,
                    display: { sm: "block" },
                  }}
                />
                {route.items &&
                  route.items.length > 0 &&
                  (isOpen ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
            </ListItem>
            {isOpen && route.items && route.items.length > 0 && (
              <List component="div" disablePadding>
                {route.items.map((subRoute, subIndex) => {
                  const subSelected = location.pathname === subRoute.path;
                  return (
                    <ListItem
                      disablePadding
                      sx={{ display: "block" }}
                      key={subIndex}
                      onClick={() => {
                        navigate(subRoute.path);
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: openStateData ? "initial" : "center",
                          px: 2.5,
                          backgroundColor: subSelected
                            ? "rgb(255, 196, 54)"
                            : "inherit",
                          "&:hover": {
                            backgroundColor: "rgb(255, 196, 54)",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: openStateData ? 3 : "auto",
                            justifyContent: "center",
                            color: subSelected ? "#FF5F00" : "inherit",
                          }}
                        >
                          {iconSet(subRoute.title, subSelected)}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              noWrap
                              sx={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: openStateData ? "normal" : "nowrap",
                                fontWeight: 700,
                              }}
                            >
                              {subRoute.title}
                            </Typography>
                          }
                          sx={{
                            opacity: openStateData ? 1 : 0,
                            display: { sm: "block" },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default SideNavGen;
