// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
const baseUrl = process.env.REACT_APP_WS_URL;
const initialState = {
  user: null,
  token: null,
  user_id: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      const decoded = jwtDecode(accessToken);
      const { id } = decoded.UserInfo;
      state.user = user;
      state.token = accessToken;
      state.user_id = id;

      // Dynamically import socketService to avoid circular dependency
      const socketService = require("../ws/initializeWebSocketService").default;
      socketService.connect(baseUrl);
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
      state.user_id = null;

      // Dynamically import socketService to avoid circular dependency
      const socketService = require("../ws/initializeWebSocketService").default;
      socketService.disconnect();
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentUserId = (state) => state.auth.user_id;
