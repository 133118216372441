import React, { useEffect, useRef } from "react";
import { useGetEmployeeDTRQuery } from "../../../../app/api/users/usersApiSlice";
import {
  Grid,
  Paper,
  Stack,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Divider,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import "./employeeDTR.css";
import IconMenu from "../../../../components/buttons/ButtonList";
import { useReactToPrint } from "react-to-print";
import useAuth from "../../../../hooks/useAuth";
import { useLocation } from "react-router-dom";

function convertTo12Hour(time24) {
  // Check if the time already contains "AM" or "PM"
  if (
    time24.toUpperCase().includes("AM") ||
    time24.toUpperCase().includes("PM")
  ) {
    return time24.trim(); // Return the time as is if it already has AM or PM
  }

  const [hours, minutes] = time24.split(":");
  const hours24 = parseInt(hours, 10);
  const period = hours24 >= 12 ? "PM" : "AM";
  const hours12 = hours24 % 12 || 12; // Convert 0 to 12 for midnight case
  return `${hours12}:${minutes} ${period}`;
}

const months = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];
//const employeeOptions = [];

// Helper function to parse time in HH:MM format
const parseTime = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return { hours, minutes };
};

// Helper function to calculate time difference in hours
const calculateTimeDifference = (start, end) => {
  if (!start || !end) return 0;

  const { hours: startHours, minutes: startMinutes } = parseTime(start);
  const { hours: endHours, minutes: endMinutes } = parseTime(end);

  const startInMinutes = startHours * 60 + startMinutes;
  const endInMinutes = endHours * 60 + endMinutes;

  return (endInMinutes - startInMinutes) / 60;
};

// Function to calculate total hours for each row
const calculateTotalHours = (record) => {
  const amHours = calculateTimeDifference(
    record.time_in_am,
    record.time_out_am
  );
  const pmHours = calculateTimeDifference(
    record.time_in_pm,
    record.time_out_pm
  );

  const totalHours = amHours + pmHours;
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);

  return { hours, minutes };
};

function generateDTRContent({
  data,
  employeeOptions,
  employeeName,
  setEmployeeName,
  dtrMonth,
  setDtrMonth,
  dtrData,
  dtrRecords,
  setdtrRecords,
  dtrTotalHours,
  setTotalHours,
  shouldFetch,
  setShouldFetch,
  listOfEmployee,
  setListOfEmployee,
  totalMinutes,
  setTotalMinutes,
  rows,
}) {
  if (!dtrRecords) return null;
  let count = 0;
  console.log(dtrRecords);
  const content = dtrRecords.map((data) => {
    count++;
    return (
      <>
        <Grid item xs={6} md={6}>
          <Paper elevation={3} className="removeShadow" sx={{ p: 2, mt: 0 }}>
            <Stack spacing={0}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  textAlign: "center",
                  mb: 2,
                }}
                gutterBottom
              >
                DAILY TIME RECORD
              </Typography>

              <TextField
                value={data.employeeName}
                disabled
                className="employeeName"
                variant="standard"
                fullWidth
              />

              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  textAlign: "center",
                }}
                gutterBottom
              >
                Name
              </Typography>
              <Stack spacing={1} direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    width: "140px",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  for the month of
                </Typography>

                <TextField
                  value={data.month}
                  disabled={data.type === "add" ? false : true}
                  variant="standard"
                  className="dtrMonth"
                  sx={{ textAlign: "center", fontWeight: 700 }}
                  fullWidth
                />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    width: "330px",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Office Hours (regular days)
                </Typography>
                <TextField
                  value={""}
                  disabled={data.type === "add" ? false : true}
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    width: "240px",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Arrival & Departure
                </Typography>
                <TextField
                  value={""}
                  disabled={data.type === "add" ? false : true}
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    width: "150px",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Saturdays
                </Typography>
                <TextField
                  value={""}
                  disabled={data.type === "add" ? false : true}
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack spacing={0} sx={{ mt: 2 }}>
                <TableContainer>
                  <Table className="tableDTR">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            width: "35px",
                          }}
                        ></TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "center",
                            border: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          AM
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "center",
                            border: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          PM
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        ></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            width: "35px",
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Time In
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Time Out
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Time In
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Time Out
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Hours
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Min.
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row}>
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              width: "35px",
                            }}
                          >
                            {row}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {data.dtr && data.dtr[row]
                              ? data.dtr[row].time_in_am
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {data.dtr && data.dtr[row]
                              ? data.dtr[row].time_out_am
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {data.dtr && data.dtr[row]
                              ? data.dtr[row].time_in_pm
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {data.dtr && data.dtr[row]
                              ? data.dtr[row].time_out_pm
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {data.dtr && data.dtr[row]
                              ? data.dtr[row].total_hours
                              : ""}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {data.dtr && data.dtr[row]
                              ? data.dtr[row].minutes
                              : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{ textAlign: "right", borderBottom: "none" }}
                          style={{
                            textAlign: "right",
                            right: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            Total
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-flex",
                                width: "40px",
                                fontWeight: 700,
                              }}
                            ></span>
                            {data.total_hours}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-flex",
                                width: "40px",
                                fontWeight: 700,
                              }}
                            ></span>
                            {data.total_minutes}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Stack>
              <Stack>
                <Typography sx={{ mt: 2, fontWeight: 600, fontSize: "14px" }}>
                  <span
                    style={{ display: "inline-flex", marginRight: "123px" }}
                  ></span>
                  I certify on my honor that the above is a true and correct
                  report of the hours of work performed, record of which was
                  made daily at the time of arrival and departure from office.
                </Typography>
                <Box sx={{ mt: 1, mb: 1 }} />
                <TextField
                  value={data?.employeeName}
                  disabled
                  variant="standard"
                  className="employeeName"
                  fullWidth
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  (Signature)
                </Typography>
                <Box sx={{ mt: 1, mb: 1 }} />
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                  Verified as to the prescribed office hours
                </Typography>

                <Box sx={{ mt: 1, mb: 1 }} />
                <TextField
                  value={data?.employee_name}
                  disabled
                  variant="standard"
                  className="employeeName"
                  fullWidth
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  (Signature)
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        {dtrRecords.length >= 3 ? (
          count % 2 === 0 ? (
            <Box className="page-break" sx={{ width: "100%" }} />
          ) : null
        ) : (
          ""
        )}
        {dtrRecords.length % 3 === 0 ? <Grid item xs={6} md={6}></Grid> : null}
      </>
    );
  });
  return content;
}

function EmployeeDTR(data) {
  const [employeeName, setEmployeeName] = React.useState();
  const [dtrMonth, setDtrMonth] = React.useState();
  const [dtrRecords, setdtrRecords] = React.useState();
  const [dtrTotalHours, setTotalHours] = React.useState(0);
  const [shouldFetch, setShouldFetch] = React.useState(false);
  const [listOfEmployee, setListOfEmployee] = React.useState(false);
  const [totalMinutes, setTotalMinutes] = React.useState(0);
  const [employeeOptions, setEmployeeOption] = React.useState([
    { value: 1, label: "Test" },
  ]);

  const location = useLocation();

  // Function to parse query parameters
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      id: params.get("id") ? params.get("id").split(",").map(Number) : [],
      month: params.get("month"),
      year: params.get("year"),
      period: params.get("period"),
    };
  };

  const queryParams = getQueryParams(location.search);

  const { branch_no } = useAuth();

  const { data: dtrData, isLoading } = useGetEmployeeDTRQuery(
    {
      user_id: queryParams.id,
      year: queryParams.year,
      month: queryParams.month,
      period: queryParams.period,
      branch_id: branch_no,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const rows = Array.from({ length: 31 }, (_, index) => index + 1);

  useEffect(() => {
    let dtrArray = [];
    if (dtrData && !isLoading) {
      dtrData.forEach((data) => {
        const dtrMontData = dayjs(
          queryParams.year + "-" + queryParams.month + "-01"
        ).format("MMMM YYYY");
        console.log(dtrMontData);
        const processedDTR = processDTRArray(data.dtr);
        dtrArray.push({
          dtr: processedDTR.dtrArray,
          total_hours: processedDTR.totalHoursAll,
          total_minutes: processedDTR.totalMinutesAll,
          employeeName: `${data.last_name}, ${data.first_name} ${data.middle_name}`,
          month: dtrMontData,
        });
      });
    }
    setdtrRecords(dtrArray);
  }, [dtrData, isLoading]);

  const processDTRArray = (dtr) => {
    let dtrArray = [];
    dtr.forEach((data) => {
      const indexDate = dayjs(data.dtr_date).date();

      // Initialize the day's entry if it doesn't exist
      if (!dtrArray[indexDate]) {
        dtrArray[indexDate] = {
          id: indexDate,
          time_in_am: "",
          time_out_am: "",
          time_in_pm: "",
          time_out_pm: "",
          hours_am: 0,
          hours_pm: 0,
          minutes_am: 0,
          minutes_pm: 0,
          total_hours: 0,
          minutes: 0,
        };
      }
      if (data.type === "AM") {
        dtrArray[indexDate].time_in_am = convertTo12Hour(data.time_in);
        dtrArray[indexDate].time_out_am = convertTo12Hour(data.time_out);
        dtrArray[indexDate].hours_am = calculateTimeDifference(
          data.time_in,
          data.time_out
        );
      } else if (data.type === "PM") {
        dtrArray[indexDate].time_in_pm = convertTo12Hour(data.time_in);
        dtrArray[indexDate].time_out_pm = convertTo12Hour(data.time_out);
        dtrArray[indexDate].hours_pm = calculateTimeDifference(
          data.time_in,
          data.time_out
        );
      }
      const totalHours =
        dtrArray[indexDate].hours_am + dtrArray[indexDate].hours_pm;
      const hours = Math.floor(totalHours);
      const minutes = Math.round((totalHours - hours) * 60);
      dtrArray[indexDate].total_hours = hours || 0;
      dtrArray[indexDate].minutes = minutes || 0;
    });
    // setdtrRecords(dtrArray);

    let totalHoursAll = 0;
    let totalMinutesAll = 0;
    dtrArray.forEach((entry) => {
      totalHoursAll += entry.total_hours;
      totalMinutesAll += entry.minutes;
    });
    // Convert total minutes to hours and minutes
    const additionalHours = Math.floor(totalMinutesAll / 60);
    totalHoursAll += additionalHours;
    totalMinutesAll = totalMinutesAll % 60;

    //setTotalHours(totalHoursAll);
    //setTotalMinutes(totalMinutesAll);

    return { dtrArray, totalHoursAll, totalMinutesAll };
  };

  const componentRef = React.useRef();
  const styleRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Custom Print Document",
    onBeforeGetContent: () => {
      // Dynamically create and add the print styles
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        @media print {
          @page {
            size: portrait !important; /* Set size to long (legal) and landscape */
          }
          /* Additional print-specific styles */
          .print-content {
            font-size: 16px;
            color: black;
          }
        }
      `;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement; // Store reference to the style element
    },
    onAfterPrint: () => {
      // Remove the dynamically added style after printing
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    },
  });

  let content = null;
  let total_hours_data = 0;
  let total_minutes_data = 0;
  content = (
    <>
      <IconMenu type={"EmployeeDTR"} handlePrint={handlePrint} />
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "center" }}
        ref={componentRef}
        className="dtrPrintContainer"
      >
        {generateDTRContent({
          data,
          employeeOptions,
          employeeName,
          setEmployeeName,
          dtrMonth,
          setDtrMonth,
          dtrData,
          dtrRecords,
          setdtrRecords,
          dtrTotalHours,
          setTotalHours,
          shouldFetch,
          setShouldFetch,
          listOfEmployee,
          setListOfEmployee,
          totalMinutes,
          setTotalMinutes,
          rows,
        })}
      </Grid>
    </>
  );
  return content;
}

export default EmployeeDTR;
