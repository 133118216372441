import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const Administrative = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();

  let content = null;
  switch (type) {
    case "edit":
      navigate("/manage-admin/edit/" + id);
      break;
    case "add":
      content = <h1>Add Administrative</h1>;
      break;
    default:
      content = <h1>Administrative Data</h1>;
      break;
  }
  return content;
};

export default Administrative;
