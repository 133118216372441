import { Paper } from "@mui/material";
import React, { useState } from "react";
import PersonalInformation from "./employeeForms/PersonalInformation";
import EducationalBackground from "./employeeForms/EducationalBackground";
import { ModalData } from "../../../components/modal/Modal";
import Camera from "../../../components/camera/Camera";
import WorkExperience from "./employeeForms/WorkExperience";
import EmployeeAssignment from "./employeeForms/EmployeeAssignment";
import DocumentAttachments from "./employeeForms/DocumentAttachments";
import BenefitsInformaton from "./employeeForms/BenefitsInformaton";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useAddUserMutation } from "../../../app/api/users/usersApiSlice";

function EmployeeAdd() {
  const [openModal, setOpenModal] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
  const [documentUploaded, setDocumentUploaded] = React.useState([]);
  const [addEmployee, { isLoading }] = useAddUserMutation();
  const [employeeAssignment, setEmployeeAssignment] = useState({
    position: "",
    assignment: "",
    remarks: "",
    dateHired: "",
    dateEndContract: "",
    remarksEndContract: "",
    trainingCompletion: "",
    hoursCompleted: "",
    duration: "",
    workStatus: "",
  });
  const [personalInformation, setPersonalInformation] = useState({
    expiryDate: "",
    liscenseNo: "",
    email: "",
    fbAccount: "",
    languages: "",
    religion: "",
    gender: "",
    dateOfBirth: "",
    height: "",
    mobileNo: "",
    mothersName: "",
    fathersName: "",
    nationality: "",
    address: "",
    lastName: "",
    middleName: "",
    firstName: "",
  });
  const [educationalBackground, setEducationalBackground] = useState({
    otherCoursesYearOfPassing: "",
    collegeYearOfPassing: "",
    hsYearOfPassing: "",
    elemYearOfPassing: "",
    otherCourses: "",
    college: "",
    hs: "",
    elementary: "",
  });
  const [benefitsInformation, setBenefitsInformation] = useState({
    sss: "",
    pagibig: "",
    philhealth: "",
  });
  const [workExperiences, setWorkExperiences] = useState([
    { companyName: "", position: "", workPeriod: "" },
  ]);

  const { id, type } = useParams();

  const handleTakePhoto = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("police_clerance", documentUploaded.police ?? "");
      formData.append("medical_certificate", documentUploaded.medical ?? "");
      formData.append("diploma", documentUploaded.diploma ?? "");
      formData.append("biodata", documentUploaded.biodata ?? "");
      formData.append("philhealth", documentUploaded.philhealth ?? "");
      formData.append("profile_picture", imageData ?? "");
      formData.append(
        "personalInformation",
        JSON.stringify(personalInformation)
      );
      formData.append(
        "educationalBackground",
        JSON.stringify(educationalBackground)
      );
      formData.append("workExperiences", JSON.stringify(workExperiences));
      formData.append("employeeAssignment", JSON.stringify(employeeAssignment));
      formData.append(
        "benefitsInformation",
        JSON.stringify(benefitsInformation)
      );
      formData.append("branch_id", 1);
      formData.append("added_by", 1);
      const res = await addEmployee(formData);
      if (res) {
        console.log(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PersonalInformation
        handleTakePhoto={handleTakePhoto}
        imageData={imageData}
        setPersonalInformation={setPersonalInformation}
        personalInformation={personalInformation}
      />
      <EducationalBackground
        setEducationalBackground={setEducationalBackground}
        educationalBackground={educationalBackground}
      />
      <WorkExperience
        setWorkExperiences={setWorkExperiences}
        workExperiences={workExperiences}
      />
      <EmployeeAssignment
        setEmployeeAssignment={setEmployeeAssignment}
        employeeAssignment={employeeAssignment}
      />
      <DocumentAttachments setDocumentUploaded={setDocumentUploaded} />
      <BenefitsInformaton
        setDocumentUploaded={setDocumentUploaded}
        setBenefitsInformation={setBenefitsInformation}
        benefitsInformation={benefitsInformation}
      />
      <Paper sx={{ p: 2, mt: 2, textAlign: "center" }} elevation={3}>
        <LoadingButton
          color="secondary"
          loading={isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={handleSubmit}
          type="submit"
          sx={{ fontSize: "1rem" }}
        >
          <span>Save</span>
        </LoadingButton>
      </Paper>
      {openModal && (
        <ModalData
          openModal={openModal}
          closeModal={handleCloseModal}
          bodyContent={
            <Camera onClose={handleCloseModal} onConfirm={setImageData} />
          }
          type={"takePhoto"}
        />
      )}
    </>
  );
}

export default EmployeeAdd;
