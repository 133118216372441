import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";

function PersonalBackground() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} className="vertical-text">
          PEROSONAL BACKGROUND
        </Grid>
        <Grid item xs={12} md={10}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    <TextField label="Address" />
                  </TableCell>
                  <TableCell>
                    <TextField label="Contact Number" />
                  </TableCell>
                  <TableCell>
                    <TextField label="Email Address" />
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default PersonalBackground;
