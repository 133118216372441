import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  Divider,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import React, { useState } from "react";

function EmployeeAssignment({ employeeAssignment, setEmployeeAssignment }) {
  const [error, setError] = useState(false);

  const handleChange = (field, value) => {
    setEmployeeAssignment((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 2 }}
        className="memberFormData"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
              EMPLOYEE ASSIGNMENT
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Position:</Typography>
              <TextField
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.position}
                onChange={(e) => handleChange("position", e.target.value)}
                variant="standard"
                helperText={error ? "Please input position" : ""}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Assignment:</Typography>
              <TextField
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.assignment}
                onChange={(e) => handleChange("assignment", e.target.value)}
                variant="standard"
                helperText={error ? "Please input Assignment" : ""}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Remarks:</Typography>
              <TextField
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.remarks}
                onChange={(e) => handleChange("remarks", e.target.value)}
                variant="standard"
                helperText={error ? "Please input Remarks" : ""}
              />
            </Stack>
          </Grid>
          <Divider />
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "200px" }}>
                Date Hired:
              </Typography>
              <TextField
                label=""
                type="date"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.dateHired}
                onChange={(e) => handleChange("dateHired", e.target.value)}
                variant="standard"
                helperText={error ? "Please select date" : ""}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "280px" }}>
                Date End Contract:
              </Typography>
              <TextField
                label=""
                type="date"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.dateEndContract}
                onChange={(e) =>
                  handleChange("dateEndContract", e.target.value)
                }
                variant="standard"
                helperText={error ? "Please select date" : ""}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Remarks:</Typography>
              <TextField
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.remarksEndContract}
                onChange={(e) =>
                  handleChange("remarksEndContract", e.target.value)
                }
                variant="standard"
                helperText={error ? "Please input remarks" : ""}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "250px" }}>
                Training Completion:
              </Typography>
              <TextField
                label=""
                type="date"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.trainingCompletion}
                onChange={(e) =>
                  handleChange("trainingCompletion", e.target.value)
                }
                variant="standard"
                helperText={error ? "Please select date" : ""}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "200px" }}>
                Hours Completed:
              </Typography>
              <TextField
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.hoursCompleted}
                onChange={(e) => handleChange("hoursCompleted", e.target.value)}
                variant="standard"
                helperText={error ? "Please input hours" : ""}
              />
            </Stack>
          </Grid>
          <Divider />
          <Grid item xs={12} md={6}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Duration:</Typography>
              <Select
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.duration}
                onChange={(e) => handleChange("duration", e.target.value)}
                variant="standard"
                helperText={error ? "Please select duration" : ""}
              >
                <MenuItem value={1}>6 Month</MenuItem>
                <MenuItem value={2}>1 Year</MenuItem>
                <MenuItem value={3}>3 Years</MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "150px" }}>
                Work Status:
              </Typography>
              <Select
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={employeeAssignment.workStatus}
                onChange={(e) => handleChange("workStatus", e.target.value)}
                variant="standard"
                helperText={error ? "Please select work status" : ""}
              >
                <MenuItem value={1}>Job Order</MenuItem>
                <MenuItem value={2}>Regular</MenuItem>
                <MenuItem value={3}>Contractual</MenuItem>
                <MenuItem value={4}>Casual</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default EmployeeAssignment;
