import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Typography from "@mui/material/Typography";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import Cloud from "@mui/icons-material/Cloud";
import PrintIcon from "@mui/icons-material/Print";

export default function IconMenu(data) {
  return (
    <Paper
      sx={{
        width: 110,
        maxWidth: "100%",
        display: "flex",
        position: "fixed",
        right: "25px",
        top: "85px", // Adjust the top position as needed
      }}
    >
      <MenuList sx={{ width: "100%", padding: 0 }} className="iconMenuBtn">
        {data.type === "add" && (
          <MenuItem
            key="add"
            sx={{ padding: "10px" }}
            onClick={data.handleSaveDTR}
          >
            <ListItemIcon>
              <SaveAsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Save</ListItemText>
          </MenuItem>
        )}
        {data.type === "EmployeeDTR" && [
          <MenuItem
            key="print"
            sx={{ padding: "10px" }}
            onClick={data.handlePrint}
          >
            <ListItemIcon>
              <PrintIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Print</ListItemText>
          </MenuItem>,
          // Add more MenuItem components here if needed
        ]}
      </MenuList>
    </Paper>
  );
}
