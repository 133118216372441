import {
  Box,
  Paper,
  TableContainer,
  Typography,
  TableCell,
  Table,
  TableRow,
  Stack,
  TableHead,
  TableBody,
  Tab,
  FormControl,
  Select,
  MenuItem,
  TextField,
  TableFooter,
} from "@mui/material";
import React, { useState, useRef } from "react";
import IconMenu from "../../../../components/buttons/ButtonList";
import { useReactToPrint } from "react-to-print";
import "./create13thMonth.css";

const decimalFormat = (num) =>
  num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
const rowTable = [
  {
    id: 1,
    name: "CALAMBA, FREDERICO",
    jan1: "13",
    jan2: "13",
    feb1: "13",
    feb2: "13",
    mar1: "13",
    mar2: "13",
    apr1: "13",
    apr2: "12",
    may1: "13",
    may2: "13",
    jun1: "13",
    jun2: "12",
    jul1: "13",
    jul2: "13",
    aug1: "13",
    aug2: "13",
    sept1: "13",
    sept2: "13",
    oct1: "13",
    oct2: "13",
    nov1: "13",
    nov2: "13",
    dec1: "12",
    dec2: "12",
    totalDays: 308,
    dailyRate: 381,
    amount: 14668.5,
    remarks: "ATM",
    assign_area: "KCC",
  },
  {
    id: 2,
    name: "DEL ROSARIO, JOHN HARRIS",
    jan1: "13",
    jan2: "13",
    feb1: "13",
    feb2: "13",
    mar1: "13",
    mar2: "13",
    apr1: "13",
    apr2: "12",
    may1: "13",
    may2: "13",
    jun1: "13",
    jun2: "12",
    jul1: "13",
    jul2: "13",
    aug1: "13",
    aug2: "13",
    sept1: "13",
    sept2: "13",
    oct1: "13",
    oct2: "13",
    nov1: "13",
    nov2: "13",
    dec1: "12",
    dec2: "12",
    totalDays: 308,
    dailyRate: 381,
    amount: 14668.5,
    remarks: "ATM",
    assign_area: "CITY HALL",
  },
  {
    id: 3,
    name: "FABIAN, MERVIN",
    jan1: "13",
    jan2: "13",
    feb1: "13",
    feb2: "13",
    mar1: "13",
    mar2: "13",
    apr1: "13",
    apr2: "12",
    may1: "13",
    may2: "13",
    jun1: "13",
    jun2: "12",
    jul1: "13",
    jul2: "13",
    aug1: "13",
    aug2: "13",
    sept1: "13",
    sept2: "13",
    oct1: "13",
    oct2: "13",
    nov1: "13",
    nov2: "13",
    dec1: "12",
    dec2: "12",
    totalDays: 308,
    dailyRate: 381,
    amount: 14668.5,
    remarks: "ATM",
    assign_area: "KCC",
  },
  {
    id: 4,
    name: "TEST, USER",
    jan1: "13",
    jan2: "13",
    feb1: "13",
    feb2: "13",
    mar1: "13",
    mar2: "13",
    apr1: "13",
    apr2: "12",
    may1: "13",
    may2: "13",
    jun1: "13",
    jun2: "12",
    jul1: "13",
    jul2: "13",
    aug1: "13",
    aug2: "13",
    sept1: "13",
    sept2: "13",
    oct1: "13",
    oct2: "13",
    nov1: "13",
    nov2: "13",
    dec1: "12",
    dec2: "12",
    totalDays: 308,
    dailyRate: 381,
    amount: 14668.5,
    remarks: "ATM",
    assign_area: "CITY HALL",
  },
];

function Create13thMonth() {
  // States for filter and search
  const [selectedArea, setSelectedArea] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  // Handle filter change (assign_area)
  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const componentRef = React.useRef();
  const styleRef = useRef();

  // Group by assign_area
  const groupedByAssignArea = rowTable.reduce((acc, row) => {
    const area = row.assign_area;
    if (!acc[area]) {
      acc[area] = [];
    }
    acc[area].push(row);
    return acc;
  }, {});

  // Filter and search logic
  const filteredAreas = Object.keys(groupedByAssignArea).reduce((acc, area) => {
    // Check if the area matches the selected area or if "all" is selected
    if (selectedArea === "all" || area === selectedArea) {
      // Filter employees in the current area based on the search term
      const filteredEmployees = groupedByAssignArea[area].filter((row) =>
        row.name.toLowerCase().includes(searchTerm)
      );

      // If there are employees matching the search term, add them to the result
      if (filteredEmployees.length > 0) {
        acc[area] = filteredEmployees;
      }
    }
    return acc;
  }, {});
  const totalAmount = rowTable.reduce((acc, row) => acc + row.amount, 0);
  // Generate the table rows
  const generateRow = Object.keys(filteredAreas).map((area) => {
    return (
      <>
        {/* Render the assign_area heading */}
        <TableRow key={area}>
          <TableCell className="sticky-column sticky-id"></TableCell>
          <TableCell className="sticky-column sticky-name">{area}</TableCell>
          <TableCell colSpan={28}></TableCell>
        </TableRow>

        {/* Render employees under the assign_area */}
        {groupedByAssignArea[area].map((row) => (
          <TableRow key={row.id}>
            <TableCell className="sticky-column sticky-id">{row.id}</TableCell>
            <TableCell className="sticky-column sticky-name">
              <Typography
                className="tableCellName"
                sx={{ width: "300px", display: "flex", fontWeight: 700 }}
              >
                {row.name}
              </Typography>
            </TableCell>
            <TableCell>{row.jan1}</TableCell>
            <TableCell>{row.jan2}</TableCell>
            <TableCell>{row.feb1}</TableCell>
            <TableCell>{row.feb2}</TableCell>
            <TableCell>{row.mar1}</TableCell>
            <TableCell>{row.mar2}</TableCell>
            <TableCell>{row.apr1}</TableCell>
            <TableCell>{row.apr2}</TableCell>
            <TableCell>{row.may1}</TableCell>
            <TableCell>{row.may2}</TableCell>
            <TableCell>{row.jun1}</TableCell>
            <TableCell>{row.jun2}</TableCell>
            <TableCell>{row.jul1}</TableCell>
            <TableCell>{row.jul2}</TableCell>
            <TableCell>{row.aug1}</TableCell>
            <TableCell>{row.aug2}</TableCell>
            <TableCell>{row.sept1}</TableCell>
            <TableCell>{row.sept2}</TableCell>
            <TableCell>{row.oct1}</TableCell>
            <TableCell>{row.oct2}</TableCell>
            <TableCell>{row.nov1}</TableCell>
            <TableCell>{row.nov2}</TableCell>
            <TableCell>{row.dec1}</TableCell>
            <TableCell>{row.dec2}</TableCell>
            <TableCell>{row.totalDays}</TableCell>
            <TableCell className="dail_rate">
              {decimalFormat(row.dailyRate / 8)}
            </TableCell>
            <TableCell className="amount">
              {decimalFormat(row.amount)}
            </TableCell>
            <TableCell>{row.remarks}</TableCell>
          </TableRow>
        ))}
      </>
    );
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Payroll Report",
    onBeforeGetContent: () => {
      // Dynamically create and add the print styles
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        @media print {
          @page {
            size: 8.5in 14in landscape; /* Set size to long (legal) and landscape */
          }
          .print-content {
            font-size: 12px;
            color: black;
          }
          .tablePayrollContainer {
            page-break-inside: auto;
          }
          .tablePayrollContainer table {
            width: 100%;
            border-collapse: collapse;
          }
          .tablePayrollContainer thead {
            display: table-header-group;
          }
          .tablePayrollContainer tbody {
            display: table-row-group;
          }
          .tablePayrollContainer tr {
            page-break-inside: auto;
          }
          .tablePayrollContainer tr {
            break-inside: avoid;
          }
        }
      `;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement; // Store reference to the style element
    },
    onAfterPrint: () => {
      // Remove the dynamically added style after printing
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    },
  });

  return (
    <>
      <IconMenu type={"EmployeeDTR"} handlePrint={handlePrint} />
      <Paper
        sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}
        className="payrollPaperContainer"
        ref={componentRef}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {/* Filter by assign_area */}

          {/* Search by name 
        <TextField
          label="Search by name"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
        />*/}
          <Typography variant="h4" align="center">
            13th Month Payroll
          </Typography>
        </Box>
        <TableContainer className="tablePayrollContainer">
          <Table className="table13th">
            <TableHead>
              <TableRow>
                <TableCell className="sticky-column sticky-id"></TableCell>
                <TableCell className="sticky-column sticky-name">
                  <FormControl sx={{ width: "100%" }} className="dar">
                    <Select
                      value={selectedArea}
                      onChange={handleAreaChange}
                      displayEmpty
                    >
                      <MenuItem value="all">All Areas</MenuItem>
                      {Object.keys(groupedByAssignArea).map((area) => (
                        <MenuItem key={area} value={area}>
                          {area}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography className="darText">DAR</Typography>
                </TableCell>
                <TableCell colSpan={2}>JAN</TableCell>
                <TableCell colSpan={2}>FEB</TableCell>
                <TableCell colSpan={2}>MAR</TableCell>
                <TableCell colSpan={2}>APR</TableCell>
                <TableCell colSpan={2}>MAY</TableCell>
                <TableCell colSpan={2}>JUN</TableCell>
                <TableCell colSpan={2}>JUL</TableCell>
                <TableCell colSpan={2}>AUG</TableCell>
                <TableCell colSpan={2}>SEPT</TableCell>
                <TableCell colSpan={2}>OCT</TableCell>
                <TableCell colSpan={2}>NOV</TableCell>
                <TableCell colSpan={2}>DEC</TableCell>
                <TableCell rowSpan={2}>TOTAL NO OF DAYS</TableCell>
                <TableCell rowSpan={2}>DAILY RATE</TableCell>
                <TableCell rowSpan={2}>AMOUNT</TableCell>
                <TableCell rowSpan={2}>REMARKS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="sticky-column sticky-id"></TableCell>
                <TableCell className="sticky-column sticky-name"></TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    1-15
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ width: "37px", display: "flex" }}
                  >
                    16-31
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="sticky-column sticky-id"></TableCell>
                <TableCell className="sticky-column sticky-name">
                  NAME
                </TableCell>
                <TableCell colSpan={28}></TableCell>
              </TableRow>
              {generateRow}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={29}
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total Amount:
                </TableCell>
                <TableCell>{decimalFormat(totalAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={30}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 4,
                    }}
                  >
                    <Typography variant="body1">
                      Prepared by: ___________
                    </Typography>
                    <Typography variant="body1">
                      Approved by: ___________
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default Create13thMonth;
