import { Button, Grid, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import { useAddAttendanceMutation } from "../../app/api/frontAction/frontDtrSlice";

function EmployeeDTR() {
  const [addAttendance] = useAddAttendanceMutation();
  const [employeeId, setEmployeeId] = useState("");
  const [error, setError] = useState("");

  const handleAttendance = async () => {
    try {
      const res = await addAttendance({ employee_id: employeeId }).unwrap();
      setError(<p style={{ color: "green" }}>{res?.message}</p>);
      console.log("Success:", res); // You can handle success state here
    } catch (err) {
      console.error("Error:", err);
      setError(err?.data?.message || "An error occurred.");
    }
  };

  return (
    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="employee_id"
            label="Employee ID"
            variant="outlined"
            onChange={(e) => setEmployeeId(e.target.value)}
            value={employeeId}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAttendance}
          >
            ATTENDANCE
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default EmployeeDTR;
