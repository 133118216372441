import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mOpen: true,
};

const menuCollapse = createSlice({
  name: "menu",
  initialState,
  reducers: {
    openClose: (state, action) => {
      // Toggle the value of mOpen
      state.mOpen = !state.mOpen;
    },
  },
});

export const { openClose } = menuCollapse.actions;

export default menuCollapse.reducer;

// Selector function to get the mOpen state
export const selectedMenuState = (state) => state.menu.mOpen;
