// Layout.js
import { Box } from "@mui/material";
import React from "react";
import Navbar from "../features/dashboard/NavList";
import Sidenav from "../features/dashboard/SideNavBar";
import Footer from "./shared/Footer";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <Box height={60} />
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: "90%" }}>
          {children}
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default Layout;
