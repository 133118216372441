import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  Button,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import "./personal.css";

function PersonalInformation(data) {
  const [error, setError] = useState(false);
  const handleChange = (field, value) => {
    data.setPersonalInformation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
          PERSONAL INFORMATION
        </Typography>
        <Grid container spacing={2} className="personal-info-grid">
          <Grid item xs={12} md={5}>
            <Stack direction={"column"} spacing={1}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "185px" }} variant="div">
                  Name:
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    label="Firstname"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.personalInformation.firstName}
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    helperText={error ? "Please input firstname" : ""}
                  />
                  <TextField
                    label="Middlename"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.personalInformation.middleName}
                    onChange={(e) => handleChange("middleName", e.target.value)}
                    helperText={error ? "Please input middlename" : ""}
                  />
                  <TextField
                    label="Lastname"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.personalInformation.lastName}
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    helperText={error ? "Please input lastname" : ""}
                  />
                </Stack>
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Address:
                </Typography>
                <TextField
                  label="Address"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  helperText={error ? "Please input address" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Nationality:
                </Typography>
                <TextField
                  label="Nationality"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.nationality}
                  onChange={(e) => handleChange("nationality", e.target.value)}
                  helperText={error ? "Please input nationality" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Father's Name:
                </Typography>
                <TextField
                  label="Father's Name"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.fathersName}
                  onChange={(e) => handleChange("fathersName", e.target.value)}
                  helperText={error ? "Please input father's name" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Mother's Name:
                </Typography>
                <TextField
                  label="Mother's name"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.mothersName}
                  onChange={(e) => handleChange("mothersName", e.target.value)}
                  helperText={error ? "Please input mothers name" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Mobile No:
                </Typography>
                <TextField
                  label="Phone Number"
                  type="number"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.mobileNo}
                  onChange={(e) => handleChange("mobileNo", e.target.value)}
                  helperText={error ? "Please input mobile number" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Height:
                </Typography>
                <TextField
                  label="height"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.height}
                  onChange={(e) => handleChange("height", e.target.value)}
                  helperText={error ? "Please input height" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Date of Birth:
                </Typography>
                <TextField
                  label=""
                  type="date"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.dateOfBirth}
                  onChange={(e) => handleChange("dateOfBirth", e.target.value)}
                  helperText={error ? "Please select your date of birth" : ""}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack direction={"column"} spacing={1}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Gender:
                </Typography>
                <Select
                  label="gender"
                  style={{ width: "100%" }}
                  error={error}
                  defaultValue={"Male"}
                  value={data.personalInformation.gender}
                  onChange={(e) => handleChange("gender", e.target.value)}
                  helperText={error ? "Please select gender" : ""}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Religion:
                </Typography>
                <Select
                  label="Religion"
                  style={{ width: "100%" }}
                  error={error}
                  defaultValue={"Christian"}
                  value={data.personalInformation.religion}
                  onChange={(e) => handleChange("religion", e.target.value)}
                  helperText={error ? "Please select religion" : ""}
                >
                  <MenuItem value="Christian">Christian</MenuItem>
                  <MenuItem value="Muslim">Muslim</MenuItem>
                  <MenuItem value="Buddhist">Buddhist</MenuItem>
                  <MenuItem value="Hindu">Hindu</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Languages:
                </Typography>
                <Select
                  label="Languages"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.languages}
                  onChange={(e) => handleChange("languages", e.target.value)}
                  defaultValue={"English"}
                  helperText={error ? "Please select language" : ""}
                >
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Filipino">Filipino</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  FB Account:
                </Typography>
                <TextField
                  label="FB Account"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.fbAccount}
                  onChange={(e) => handleChange("fbAccount", e.target.value)}
                  helperText={error ? "Please input FB account link" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Email:
                </Typography>
                <TextField
                  label="Email Address"
                  type="email"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  helperText={error ? "Please input valid email address" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Liscense No:
                </Typography>
                <TextField
                  label="Liscense No"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.liscenseNo}
                  onChange={(e) => handleChange("liscenseNo", e.target.value)}
                  helperText={error ? "Please input liscense" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Expiry Date:
                </Typography>
                <TextField
                  label=""
                  type="date"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.personalInformation.expiryDate}
                  onChange={(e) => handleChange("expiryDate", e.target.value)}
                  helperText={error ? "Please input valid date" : ""}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={2} className="move-to-first-column">
            {data.imageData ? (
              <img
                src={data.imageData}
                alt="image"
                style={{ width: "100%", height: "150px" }}
              />
            ) : (
              <Box
                sx={{ width: "100%", height: "150px", border: "2px solid" }}
              ></Box>
            )}

            <Button
              color="primary"
              variant="contained"
              sx={{ width: "100%", marginTop: "10px" }}
              onClick={data.handleTakePhoto}
            >
              Take Photo
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default PersonalInformation;
