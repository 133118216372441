import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Camera from "../camera/Camera";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  minHeight: 500,
  //overflowY: "auto", // Enable vertical scroll if content overflows
  borderRadius: "10px",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flow-root",
  maxHeight: "95vh",
  "@media (max-width: 600px)": {
    width: "90%",
    overflowY: "auto",
  },
};
export const ModalData = ({
  closeModal,
  openModal,
  title,
  type,
  profileName,
  cameraImg,
  bodyContent,
}) => {
  let content = "";
  switch (type) {
    case "takePhoto":
      content = bodyContent;
      break;
    case "importEmployee":
      content = bodyContent;
    case "importPayroll":
      content = bodyContent;
      break;
    default:
      break;
  }
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalData"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
            }}
            variant="h6"
            component="h2"
          >
            {title}
          </Typography>
          <Box sx={{ marginBottom: "20px" }} />
          {content}
        </Box>
      </Modal>
    </div>
  );
};
