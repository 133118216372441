import React from "react";
import { useGetLoginUsersQuery } from "../../../app/api/users/usersApiSlice";

function PayrollForms() {
  const { data: users, isFetching } = useGetLoginUsersQuery("");

  return (
    <div>
      {isFetching ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {users &&
            users.map((user) => (
              <li key={user.id} style={{ color: "black" }}>
                {user.username}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default PayrollForms;
