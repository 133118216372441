import { Paper, Typography } from "@mui/material";
import React from "react";

function ExpiredWidget() {
  return (
    <Paper
      elevation={3}
      sx={{ p: 2, maxHeight: "435px", height: "800px", overflow: "auto" }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textAlign: "center",
          backgroundColor: "#E1F7F5",
          color: "black",
        }}
      >
        ID and License Status
      </Typography>
    </Paper>
  );
}

export default ExpiredWidget;
