class WebSocketService {
  static instance = null;
  socket = null;
  store = null;
  apiSlice = null;
  url = null;
  retryInterval = 5000; // Retry connection every 5 seconds
  retryTimeout = null;
  intentionalDisconnect = false;

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  initialize(store, apiSlice) {
    this.store = store;
    this.apiSlice = apiSlice;
  }

  connect(url) {
    this.url = url;
    this.intentionalDisconnect = false; // Reset the flag when trying to connect
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.socket = new WebSocket(url);
      this.socket.onopen = () => {
        console.log("WebSocket connected");
        this.clearRetryTimeout(); // Clear retry timeout upon successful connection
      };
      this.socket.onclose = () => {
        console.log("WebSocket disconnected");
        if (!this.intentionalDisconnect) {
          this.scheduleRetry();
        }
      };
      this.socket.onmessage = this.onMessageHandler;
    }
  }

  disconnect() {
    if (this.socket) {
      this.intentionalDisconnect = true; // Set the flag to true when disconnecting intentionally
      this.clearRetryTimeout(); // Clear retry timeout if disconnecting intentionally
      this.socket.close();
      this.socket = null;
    }
  }

  onMessageHandler = (message) => {
    if (this.store && this.apiSlice) {
      const { type, data } = JSON.parse(message.data);
      console.log("Received message:", type);
      switch (type) {
        case "refresh":
          this.store.dispatch(
            this.apiSlice.util.invalidateTags(["Users", "Loans", "Payments"])
          );
          break;
        default:
          console.log("Unknown message type:", this.socket.readyState);
          break;
      }
    }
  };

  sendMessage(data) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    } else {
      console.warn(
        "WebSocket is not open. ReadyState:",
        this.socket ? this.socket.readyState : "No socket"
      );
    }
  }

  scheduleRetry() {
    if (!this.retryTimeout) {
      this.retryTimeout = setTimeout(() => {
        console.log("Retrying connection...");
        this.connect(this.url); // Attempt to reconnect
        this.retryTimeout = null;
      }, this.retryInterval);
    }
  }

  clearRetryTimeout() {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;
    }
  }
}

export default WebSocketService;
