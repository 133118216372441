// pageRoutes.js
import React from "react";
import CustomRoute from "./CustomRoutes";
import Login from "../features/login/Login";
import Dashboard from "../features/dashboard/Dashboard";
import Employee from "../features/admin/employee/Employee";
import Attendance from "../features/admin/attendance/Attendance";
import Payroll from "../features/admin/payroll/Payroll";
import Page404 from "../features/404/Page404";
import { ROLES } from "../config/roles";
import PaySlip from "../features/admin/payroll/pages/PaySlip";
import Administrative from "../features/admin/administrative/Administrative.js";
import EmployeeDTR from "../features/frontend/EmployeeDTR.js";
import Create13thMonth from "../features/admin/payroll/pages/Create13thMonth.js";

const allRoles = Object.values(ROLES);

const router = [
  {
    path: "/login",
    element: <Login />,
    title: "Login Page",
    isHidden: true,
  },
  {
    path: "/dashboard",
    element: (
      <CustomRoute
        component={Dashboard}
        allowedRoles={[ROLES.Admin, ROLES.Manager]}
        title="Home"
      />
    ),
    title: "Dashboard",
    isHidden: false,
  },
  {
    path: "/administration",
    items: [
      {
        path: "/manage-branch",
        name: "Manage Branch",
        element: (
          <CustomRoute
            component={Administrative}
            allowedRoles={[ROLES.Admin]}
            title="Manage Asignements"
          />
        ),
        title: "Manage Branch",
      },
      {
        path: "/manage-aor",
        name: "Manage Asignments",
        element: (
          <CustomRoute
            component={Administrative}
            allowedRoles={[ROLES.Admin]}
            title="Manage Asignements"
          />
        ),
        title: "Manage Asignments",
      },
    ],
    title: "Administration",
    isHidden: false,
  },
  {
    path: "/manage-employees",
    element: (
      <CustomRoute
        component={Employee}
        allowedRoles={[ROLES.Admin]}
        title="Employee data management"
      />
    ),
    title: "Employee data management",
    isHidden: false,
  },
  {
    path: "/manage-employees/:type",
    element: (
      <CustomRoute
        component={Employee}
        allowedRoles={[ROLES.Admin]}
        title="Employee data management"
      />
    ),
    title: "Employee data management",
    isHidden: true,
  },
  {
    path: "/manage-employees/:type/:id",
    element: (
      <CustomRoute
        component={Employee}
        allowedRoles={[ROLES.Admin]}
        title="Employee data management"
      />
    ),
    title: "Employee data management",
    isHidden: true,
  },
  {
    path: "/manage-attendance",
    element: (
      <CustomRoute
        component={Attendance}
        allowedRoles={[ROLES.Admin]}
        title="Time & attendance management"
      />
    ),
    title: "Time & attendance management",
    isHidden: false,
  },
  {
    path: "/manage-attendance/:type",
    element: (
      <CustomRoute
        component={Attendance}
        allowedRoles={[ROLES.Admin]}
        title="Time & attendance management"
      />
    ),
    title: "Time & attendance management",
    isHidden: true,
  },
  {
    path: "/manage-attendance/:type/:id/:name/:year/:month/:period",
    element: (
      <CustomRoute
        component={Attendance}
        allowedRoles={[ROLES.Admin]}
        title="Time & attendance management"
      />
    ),
    title: "Time & attendance management",
    isHidden: true,
  },
  {
    path: "/manage-payroll",
    element: (
      <CustomRoute
        component={Payroll}
        allowedRoles={[ROLES.Admin]}
        title="Payroll processing"
      />
    ),
    title: "Payroll processing",
    isHidden: false,
  },
  {
    path: "/manage-13th-month-pay",
    element: (
      <CustomRoute
        component={Create13thMonth}
        allowedRoles={[ROLES.Admin]}
        title="13th month Payroll "
      />
    ),
    title: "13th month Payroll ",
    isHidden: false,
  },
  {
    path: "/manage-payroll/:type",
    element: (
      <CustomRoute
        component={Payroll}
        allowedRoles={[ROLES.Admin]}
        title="Payroll processing"
      />
    ),
    title: "Payroll processing",
    isHidden: true,
  },
  {
    path: "/manage-payroll/:type/:id",
    element: (
      <CustomRoute
        component={Payroll}
        allowedRoles={[ROLES.Admin]}
        title="Payroll processing"
      />
    ),
    title: "Payroll processing",
    isHidden: true,
  },
  {
    path: "/manage-reports",
    element: (
      <CustomRoute
        component={Dashboard}
        allowedRoles={[ROLES.Admin]}
        title="Reporting and analytics"
      />
    ),
    title: "Reporting and analytics",
    isHidden: true,
  },
  {
    path: "DTR",
    element: <EmployeeDTR />,
    title: "Employee DTR",
    isHidden: true,
  },
  {
    path: "/*",
    element: (
      <CustomRoute
        component={Page404}
        allowedRoles={allRoles}
        title="Reporting and analytics"
      />
    ),
    isHidden: true,
  },
];

export default router;
