import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import router from "./utils/pageRoutes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
export default function App() {
  const routerData = createBrowserRouter(router);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // Set default timezone, for example to 'America/New_York'
  dayjs.tz.setDefault("Asia/Manila");

  return (
    <>
      <RouterProvider router={routerData} />
    </>
  );
}
