import { Box, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import UploadButton from "../../../../components/attachments/UploadFileData";

function DocumentAttachments({ setDocumentUploaded }) {
  const [error, setError] = useState(false);
  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Grid container spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={12} md={2.4} sx={{ textAlign: "center" }}>
            <UploadButton
              title={"POLICE CLEARANCE"}
              keyValue={"police"}
              setDocumentUploaded={setDocumentUploaded}
            />
          </Grid>
          <Grid item xs={12} md={2.4} sx={{ textAlign: "center" }}>
            <UploadButton
              title={"NBI CLEARANCE"}
              keyValue={"nbi"}
              setDocumentUploaded={setDocumentUploaded}
            />
          </Grid>
          <Grid item xs={12} md={2.4} sx={{ textAlign: "center" }}>
            <UploadButton
              title={"MEDICAL CERTIFICATE"}
              keyValue={"medical"}
              setDocumentUploaded={setDocumentUploaded}
            />
          </Grid>
          <Grid item xs={12} md={2.4} sx={{ textAlign: "center" }}>
            <UploadButton
              title={"DIPLOMA"}
              keyValue={"diploma"}
              setDocumentUploaded={setDocumentUploaded}
            />
          </Grid>
          <Grid item xs={12} md={2.4} sx={{ textAlign: "center" }}>
            <UploadButton
              title={"BIODATA"}
              keyValue={"biodata"}
              setDocumentUploaded={setDocumentUploaded}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default DocumentAttachments;
