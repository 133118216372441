import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoginUsers: builder.query({
      query: () => "/users",
      keepUnusedDataFor: 5,
      providesTags: [{ type: "Users", id: "Operators" }],
    }),
    getUsers: builder.query({
      query: ({ branch_id, id, isSuperAdmin }) => {
        return `/employee?branch_id=${branch_id}&id=${id}&isSuperAdmin=${isSuperAdmin}`;
      },
      providesTags: (result, error, arg) => [{ type: "Users", id: "Members" }],
    }),
    addUser: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/employee",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
      ],
    }),
    uploadEmployee: builder.mutation({
      query: ({ formData, type }) => ({
        url: `/csv-employee/${type}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Users", id: "Members" }],
    }),
    uploadAttendance: builder.mutation({
      query: ({ formData, type }) => ({
        url: `/csv-employee/${type}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Users", id: "Members" }],
    }),
    deleteBorrower: builder.mutation({
      query: ({ id, status, type }) => {
        return {
          url: `/delete/${id}/${status}/${type}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Users", id: "Members" }],
    }),
    getDTR: builder.query({
      query: ({ month, year, period, branch_id, isSuperAdmin }) => {
        // Ensure dates are in the correct format
        return `/employee-dtr?month=${month}&year=${year}&period=${period}&branch_id=${branch_id}&isSuperAdmin=${isSuperAdmin}`;
      },
      //query: (id) => `/employee-dtr/${id}`,
      providesTags: [{ type: "DTR", id: "DTR" }],
    }),
    getEmployeeDTR: builder.query({
      query: ({ month, year, period, user_id, branch_id, isSuperAdmin }) => {
        // Ensure dates are in the correct format
        return `/employee-dtr/employeeDTR?month=${month}&year=${year}&period=${period}&user_id=${user_id}&branch_id=${branch_id}&isSuperAdmin=${isSuperAdmin}`;
      },
      //query: (id) => `/employee-dtr/${id}`,
      providesTags: [{ type: "DTR", id: "DTR" }],
    }),
    getListEmployee: builder.query({
      query: ({ branch_id, isSuperAdmin }) => {
        // Ensure dates are in the correct format
        return `/employee/list?branch_id=${branch_id}&isSuperAdmin=${isSuperAdmin}`;
      },
      providesTags: [{ type: "Users", id: "Members" }],
    }),
    addNewDTR: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/employee-dtr",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [{ type: "DTR", id: "DTR" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useGetLoginUsersQuery,
  useDeleteBorrowerMutation,
  useUploadEmployeeMutation,
  useUploadAttendanceMutation,

  useGetDTRQuery,
  useGetEmployeeDTRQuery,
  useGetListEmployeeQuery,
  useAddNewDTRMutation,
} = usersApiSlice;
