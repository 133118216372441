import React, { useState, useEffect, useRef } from "react";
import { json, useLocation } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button,
  Autocomplete,
  Typography,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { useGetUsersQuery } from "../../../../app/api/users/usersApiSlice";
import {
  useGenerateManualPayrollMutation,
  useGetPayrollQuery,
} from "../../../../app/api/payroll/generatePayroll";
import useAuth from "../../../../hooks/useAuth";
import "./createPayroll.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const monthData = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];
const columns = [
  { id: "cont", label: "", minWidth: 50 },
  { id: "id", label: "ID No.", minWidth: 150 },
  { id: "employee", label: "Employee", minWidth: 300 },
  { id: "position", label: "Position", minWidth: 150 },
  { id: "assignment", label: "Assignment", minWidth: 150 },
  { id: "basic_salary", label: "Basic Salary", minWidth: 150 },
  { id: "sss", label: "SSS", minWidth: 150 },
  { id: "philhealth", label: "PHILHEALTH", minWidth: 150 },
  { id: "pagibig", label: "PAG-IBIG", minWidth: 150 },
  { id: "allowance", label: "Allowance", minWidth: 150 },
  { id: "bonus13th", label: "13th Month", minWidth: 150 },
  { id: "bonus5days", label: "5days", minWidth: 150 },
  { id: "days", label: "Days", minWidth: 150 },
  { id: "nd", label: "ND", minWidth: 150 },
  { id: "ot", label: "OT", minWidth: 150 },
  { id: "rd", label: "RD[Hrs] PAY", minWidth: 150 },
  { id: "satPay", label: "Saturday Pay", minWidth: 150 },
  { id: "tardy", label: "Tardy", minWidth: 150 },
  { id: "absent", label: "Absent", minWidth: 150 },
  { id: "ut", label: "Under Time", minWidth: 150 },
  { id: "gross", label: "GROSS", minWidth: 150 },
  { id: "salaryLoan", label: "SalaryLoan", minWidth: 150 },
  { id: "calamity", label: "Calamity", minWidth: 150 },
  { id: "special", label: "Special", minWidth: 150 },
  { id: "pagibigLoan", label: "PAG IBIG", minWidth: 150 },
  { id: "sssLoan", label: "SSS", minWidth: 150 },
  { id: "emergency", label: "Emergency", minWidth: 150 },
  { id: "v1", label: "V1", minWidth: 150 },
  { id: "v2", label: "V2", minWidth: 150 },
  { id: "rice", label: "Rice", minWidth: 150 },
  { id: "par", label: "Paraphernalias", minWidth: 150 },
  { id: "hc", label: "Haircut", minWidth: 150 },
  { id: "liscense", label: "Liscense", minWidth: 150 },
  { id: "total", label: "Total", minWidth: 150 },
  { id: "signature", label: "Signature", minWidth: 150 },
];

const decimalFormat = (num) =>
  num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const showMyAppToastNotification = () => {
  toast("🦄 Wow so easy!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
export default function CreatePayroll({ type }) {
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchText, setSearchText] = useState("");
  const [addingRows, setAddingRows] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [generateNewPayroll] = useGenerateManualPayrollMutation();
  const [tableRows, setTableRows] = useState([]);

  const { branch_no, id } = useAuth();

  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      month: params.get("month"),
      year: params.get("year"),
      period: params.get("period"),
      branch_id: params.get("branch_id"),
    };
  };

  const queryParams = getQueryParams(location.search);

  const [filterDTR, setFilterDTR] = useState({
    month: queryParams.month ? queryParams.month : new Date().getMonth() + 1,
    year: queryParams.year ? queryParams.year : new Date().getFullYear(),
    period: queryParams.period
      ? queryParams.period
      : new Date().getDay() > 16
      ? 1
      : 2,
    branch_id: queryParams.branch_id ? queryParams.branch_id : branch_no,
    user_id: id,
  });

  const { data: payrollGetData } = useGetPayrollQuery(
    {
      branch_id: filterDTR.branch_id,
      month: filterDTR.month,
      year: filterDTR.year,
      period: filterDTR.period,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: allUsers } = useGetUsersQuery(
    { branch_id: branch_no },
    { refetchOnMountOrArgChange: true }
  );
  const inputRefs = useRef([]);
  const rows = [];
  useEffect(() => {
    if (payrollGetData) {
      const rows = [];
      payrollGetData.forEach((payroll) => {
        let totalDeduction =
          parseFloat(payroll.sss_rate) ||
          0 + parseFloat(payroll.philhealth_rate) ||
          0 + parseFloat(payroll.pagibig_rate) ||
          0 + parseFloat(payroll.sssLoan) ||
          0 + parseFloat(payroll.pagibigLoan) ||
          0 + parseFloat(payroll.salaryLoan) ||
          0 + parseFloat(payroll.calamity) ||
          0 + parseFloat(payroll.special) ||
          0 + parseFloat(payroll.emergency) ||
          0 + parseFloat(payroll.v1) ||
          0 + parseFloat(payroll.v2) ||
          0 + parseFloat(payroll.rice) ||
          0 + parseFloat(payroll.par) ||
          0 + parseFloat(payroll.hc) ||
          0 + parseFloat(payroll.liscense) ||
          0;

        let totalSalary =
          parseFloat(payroll.salary_rate * payroll.days_work) +
          parseFloat(payroll.salary_rate / 8) *
            parseFloat(payroll.overtime || 0) *
            1.25 +
          parseFloat(payroll.salary_rate / 8) *
            parseFloat(payroll.nd || 0) *
            1.1 +
          parseFloat(payroll.salary_rate / 8) *
            parseFloat(payroll.rd || 0) *
            1.3;
        rows.push({
          type: "Edit",
          id: payroll.user_id,
          employee:
            payroll.first_name +
            " " +
            payroll.middle_name +
            " " +
            payroll.last_name,
          position: payroll.position,
          assignment: payroll.area_assign,
          basic_salary: payroll.salary_rate,
          sss: payroll.sss_rate,
          philhealth: payroll.philhealth_rate,
          pagibig: payroll.pagibig_rate,
          allowance: payroll.allowance,
          bonus13th: payroll.bonus13th,
          bonus5days: payroll.bonus5days,
          days: payroll.days_work,
          ot: payroll.overtime,
          rd: payroll.rd,
          satPay: payroll.satPay,
          tardy: payroll.tardy,
          absent: payroll.absent,
          ut: payroll.ut,
          gross: decimalFormat(totalSalary),
          salaryLoan: payroll.salaryLoan,
          calamity: payroll.calamity,
          special: payroll.special,
          pagibigLoan: payroll.pagibigLoan,
          sssLoan: payroll.sssLoan,
          emergency: payroll.emergency,
          v1: payroll.v1,
          v2: payroll.v2,
          rice: payroll.rice,
          par: payroll.par,
          hc: payroll.hc,
          liscense: payroll.liscense,
          total: decimalFormat(totalSalary - totalDeduction),
          signature: payroll.signature,
          deduction: totalDeduction,
          nd: payroll.nd,
        });
      });
      setAddingRows(rows);
    }
  }, [payrollGetData]);

  useEffect(() => {
    if (allUsers) {
      const options = allUsers
        .reduce((acc, user) => {
          const name = `${user.first_name} ${user.last_name}`;
          if (!acc.some((opt) => opt.label === name)) {
            const benefits = user.benefits.reduce((benefitsAcc, benefit) => {
              benefitsAcc[benefit.benefits_name] = benefit.amount;
              benefitsAcc[`${benefit.benefits_name}_amount`] = benefit.amount;
              return benefitsAcc;
            }, {});
            acc.push({
              label: name,
              value: String(user.id),
              ...benefits,
              assign_area: user.assigned_area,
              position: user.position,
              basic_salary: user.salary_rate,
              id: user.id,
              employee: name,
            });
          }
          return acc;
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeOptions(options);
    }
  }, [allUsers]);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setPage(0);
  };

  const handleAddClick = () => {
    setAddingRows((prev) => [
      ...prev,
      { type: "Create", cont: prev.length + 1 },
    ]);
    setTimeout(() => {
      inputRefs.current[addingRows.length]?.focus();
    }, 0);
  };

  const handleInputChange = (event, columnId, index) => {
    const { value } = event.target;
    const newRows = [...addingRows];
    const updatedRow = { ...newRows[index], [columnId]: value };

    console.log(columnId);

    // Function to calculate total salary
    const calculateTotal = (
      basicSalary,
      days,
      ot,
      nd,
      rd,
      satPay,
      tardy,
      absent,
      ut
    ) =>
      basicSalary * days +
      (basicSalary / 8) * ot * 1.25 +
      (basicSalary / 8) * nd * 1.1 +
      (basicSalary / 8) * rd * 1.3; // Adjust to add OT rate

    const calculateDeduction = (
      v1,
      v2,
      rice,
      par,
      hc,
      liscense,
      sssLoan,
      pagibigLoan,
      salaryLoan,
      calamity,
      special,
      emergency,
      sss_rate,
      philhealth_rate,
      pagibig_rate
    ) => {
      const totalDeduction =
        (parseFloat(sss_rate) || 0) +
        (parseFloat(philhealth_rate) || 0) +
        (parseFloat(pagibig_rate) || 0) +
        (parseFloat(sssLoan) || 0) +
        (parseFloat(pagibigLoan) || 0) +
        (parseFloat(salaryLoan) || 0) +
        (parseFloat(calamity) || 0) +
        (parseFloat(special) || 0) +
        (parseFloat(emergency) || 0) +
        (parseFloat(v1) || 0) +
        (parseFloat(v2) || 0) +
        (parseFloat(rice) || 0) +
        (parseFloat(par) || 0) +
        (parseFloat(hc) || 0) +
        (parseFloat(liscense) || 0);
      return totalDeduction;
    };

    // Function to calculate gross
    const calculateGross = (total, deduction) =>
      decimalFormat(parseFloat(total) - parseFloat(deduction)); // Adjust to subtract deduction from total

    if (
      columnId === "days" ||
      columnId === "basic_salary" ||
      columnId === "ot" ||
      columnId === "v1" ||
      columnId === "v2" ||
      columnId === "rice" ||
      columnId === "par" ||
      columnId === "hc" ||
      columnId === "liscense" ||
      columnId === "sssLoan" ||
      columnId === "pagibigLoan" ||
      columnId === "salaryLoan" ||
      columnId === "calamity" ||
      columnId === "special" ||
      columnId === "emergency" ||
      columnId === "sss_rate" ||
      columnId === "philhealth_rate" ||
      columnId === "pagibig_rate" ||
      columnId === "nd" ||
      columnId === "rd" ||
      columnId === "satPay" ||
      columnId === "tardy" ||
      columnId === "absent" ||
      columnId === "ut"
    ) {
      const {
        basic_salary = 0,
        days = 0,
        ot = 0,
        nd = 0,
        rd = 0,
        satPay = 0,
        tardy = 0,
        absent = 0,
        ut = 0,
      } = updatedRow;

      // Calculate total based on updated or existing values
      const total = calculateTotal(
        columnId === "basic_salary" ? value : basic_salary,
        columnId === "days" ? value : days,
        columnId === "ot" ? value : ot,
        columnId === "nd" ? value : nd,
        columnId === "rd" ? value : rd,
        columnId === "satPay" ? value : satPay,
        columnId === "tardy" ? value : tardy,
        columnId === "absent" ? value : absent,
        columnId === "ut" ? value : ut
      );

      // Calculate deduction
      const deduction = calculateDeduction(
        parseFloat(updatedRow.v1) || 0,
        parseFloat(updatedRow.v2) || 0,
        parseFloat(updatedRow.rice) || 0,
        parseFloat(updatedRow.par) || 0,
        parseFloat(updatedRow.hc) || 0,
        parseFloat(updatedRow.liscense) || 0,
        parseFloat(updatedRow.sssLoan) || 0,
        parseFloat(updatedRow.pagibigLoan) || 0,
        parseFloat(updatedRow.salaryLoan) || 0,
        parseFloat(updatedRow.calamity) || 0,
        parseFloat(updatedRow.special) || 0,
        parseFloat(updatedRow.emergency) || 0,
        parseFloat(updatedRow.sss_rate) || 0,
        parseFloat(updatedRow.philhealth_rate) || 0,
        parseFloat(updatedRow.pagibig_rate) || 0
      );

      updatedRow.total = calculateGross(total, deduction); // Assign calculated total
      updatedRow.gross = decimalFormat(total); // Assign calculated gross with deduction
    }

    newRows[index] = updatedRow;
    setAddingRows(newRows);
  };

  const handleAutocompleteChange = (event, value, columnId, index) => {
    const newRows = [...addingRows];
    if (columnId === "employee" || columnId === "id") {
      newRows[index] = {
        ...newRows[index],
        employee: value,
        id: value,
        user_id: value?.value,
        employee_name: value?.label,
        basic_salary: value?.basic_salary,
        position: value?.position,
        assignment: value?.assign_area,
        sss: value?.sss,
        philhealth: value?.philhealth,
        pagibig: value?.pagibig,
      };
    } else {
      newRows[index] = { ...newRows[index], [columnId]: value };
    }
    setAddingRows(newRows);
  };

  const filteredRows = rows.filter(
    (row) =>
      row.employee.toLowerCase().includes(searchText.toLowerCase()) ||
      row.id.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSavePayroll = async () => {
    try {
      const payrollData = [];

      addingRows.forEach((row) => {
        payrollData.push({
          ...row,
          user_id: row.user_id, // Retain the original user_id from the row
          month: filterDTR.month,
          year: filterDTR.year,
          period: filterDTR.period,
          branch_id: filterDTR.branch_id,
        });
      });

      const formData = {
        user_id: filterDTR.user_id, // Main user_id from filterDTR
        month: filterDTR.month,
        year: filterDTR.year,
        period: filterDTR.period,
        branch_id: filterDTR.branch_id,
        payroll_data: payrollData,
      };
      const res = await generateNewPayroll({ formData });
      if (res) {
        toast.success(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleMonthChange = (event) => {
    setFilterDTR({ ...filterDTR, month: event.target.value });
  };

  const handleBranchChange = (event) => {
    setFilterDTR({ ...filterDTR, branch_id: event.target.value });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" align="center">
          {type} payroll for {monthData[filterDTR.month - 1].label} {""}
          {filterDTR.year} {"Period "}
          {filterDTR.period}
        </Typography>
      </Box>
      <TableContainer className="tableContainer">
        <Table className="table" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className="tableHeaderLabel">
              <TableCell colSpan={3} className="tableWithSearch">
                <Button
                  onClick={handleAddClick}
                  variant="contained"
                  color="primary"
                >
                  Add
                </Button>
                {type === "Create" && (
                  <>
                    <Select
                      sx={{ height: "37px", ml: 2 }}
                      value={filterDTR.branch_id}
                      onChange={handleBranchChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Select Branch" }}
                    >
                      <MenuItem value="" disabled>
                        Select Branch
                      </MenuItem>
                      <MenuItem value={1}>Branch 1</MenuItem>
                      <MenuItem value={2}>Branch 2</MenuItem>
                    </Select>
                    <Select
                      sx={{ height: "37px", ml: 2 }}
                      value={filterDTR.month}
                      onChange={handleMonthChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Select Month" }}
                    >
                      <MenuItem value="" disabled>
                        Select Month
                      </MenuItem>
                      <MenuItem value={1}>January</MenuItem>
                      <MenuItem value={2}>February</MenuItem>
                      <MenuItem value={3}>March</MenuItem>
                      <MenuItem value={4}>April</MenuItem>
                      <MenuItem value={5}>May</MenuItem>
                      <MenuItem value={6}>June</MenuItem>
                      <MenuItem value={7}>July</MenuItem>
                      <MenuItem value={8}>August</MenuItem>
                      <MenuItem value={9}>September</MenuItem>
                      <MenuItem value={10}>October</MenuItem>
                      <MenuItem value={11}>November</MenuItem>
                      <MenuItem value={12}>December</MenuItem>
                    </Select>
                    <Select
                      sx={{ height: "37px", ml: 2 }}
                      value={filterDTR.period}
                      onChange={(e) =>
                        setFilterDTR({ ...filterDTR, period: e.target.value })
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "Select Period" }}
                    >
                      <MenuItem value="" disabled>
                        Select Period
                      </MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                    </Select>
                  </>
                )}
                {/*<TextField
                  id="search"
                  label="Search"
                  variant="standard"
                  value={searchText}
                  onChange={handleSearchChange}
                />*/}
              </TableCell>
              <TableCell colSpan={9}>Branch Name</TableCell>
              <TableCell colSpan={9}>ATTENDANCE</TableCell>
              <TableCell colSpan={6}>LOAN</TableCell>
              <TableCell colSpan={6}>DEDUCTION</TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
            <TableRow className="tableHeaderColumn">
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                  className={`sticky ${column.id}`}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {addingRows.map((row, index) => (
              <TableRow key={`addingRow-${index}`}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={`sticky ${column.id}`}
                  >
                    {(row["type"] === "Create" && column.id === "employee") ||
                    (row["type"] === "Create" && column.id === "id") ? (
                      <Autocomplete
                        options={employeeOptions}
                        getOptionLabel={(option) =>
                          column.id === "employee"
                            ? option.label || ""
                            : option.value || ""
                        }
                        value={
                          employeeOptions.find(
                            (opt) =>
                              opt.value ===
                              (row[column.id]?.value || row[column.id])
                          ) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(event, newValue) =>
                          handleAutocompleteChange(
                            event,
                            newValue,
                            column.id,
                            index
                          )
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                      />
                    ) : column.id === "cont" ? (
                      row.cont
                    ) : column.id === "gross" ||
                      column.id === "position" ||
                      column.id === "assignment" ||
                      column.id === "signature" ||
                      column.id === "total" ? (
                      column.id === "total" ? (
                        <Typography variant="span">
                          ₱{row[column.id]}
                        </Typography>
                      ) : (
                        row[column.id]
                      )
                    ) : row["type"] === "Edit" &&
                      (column.id === "id" || column.id === "employee") ? (
                      <TextField
                        variant="standard"
                        className="disabledTextField"
                        value={row[column.id] || ""}
                        disabled={true}
                        onChange={(event) =>
                          handleInputChange(event, column.id, index)
                        }
                        inputRef={(el) => (inputRefs.current[index] = el)}
                      />
                    ) : (
                      <TextField
                        variant="standard"
                        value={row[column.id] || ""}
                        onChange={(event) =>
                          handleInputChange(event, column.id, index)
                        }
                        inputRef={(el) => (inputRefs.current[index] = el)}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={
                          column.id === "id" || column.id === "employee"
                            ? `sticky ${column.id}`
                            : ""
                        }
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Button variant="contained" color="primary" onClick={handleSavePayroll}>
          {type === "Create" ? "Create Payroll" : "Update Payroll"}
        </Button>
      </Box>
      <ToastContainer />
    </Paper>
  );
}
