import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const UploadButton = ({ title, keyValue, iconHidden, setDocumentUploaded }) => {
  const [uploadValue, setUploadValue] = useState(null);
  const handleFileChange = (e) => {
    const files = e.target.files;
    //const keyValue = e.target.name; // Assuming the input has a name attribute for keyValue
    setUploadValue(files[0]);
    setDocumentUploaded((prevState) => {
      const updatedState = { ...prevState };

      // If keyValue already exists in state, append new files to the existing array
      if (updatedState[keyValue]) {
        updatedState[keyValue] = [
          ...updatedState[keyValue],
          ...Array.from(files),
        ];
      } else {
        // Otherwise, create a new array with the files
        updatedState[keyValue] = Array.from(files);
      }

      return updatedState;
    });
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Grid item xs={12}>
          {uploadValue ? (
            <img
              src={URL.createObjectURL(uploadValue)}
              alt="Selected"
              style={{
                maxWidth: "100%",
                width: "100%",
                height: "200px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          ) : iconHidden ? null : (
            <UploadFileIcon fontSize="large" />
          )}
        </Grid>
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          {title}
          <input
            type="file"
            accept="image/*"
            defaultValue=""
            style={{ display: "none" }}
            data-value={uploadValue}
            onChange={handleFileChange}
            multiple={true}
          />
        </Button>
      </Box>
    </>
  );
};
export default UploadButton;
