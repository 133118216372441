import { Box, Stack, Typography, TextField, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import "./personal.css";

function EducationalBackground(data) {
  const [error, setError] = useState(false);
  const handleChange = (field, value) => {
    data.setEducationalBackground((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
          EDUCATIONAL BACKGROUND
        </Typography>
        <Grid container spacing={2} className="personal-info-grid">
          <Grid item xs={12} md={5}>
            <Stack direction={"column"} spacing={1}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Elementary:
                </Typography>
                <TextField
                  label="Elementary School"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.educationalBackground.elementary}
                  onChange={(e) => handleChange("elementary", e.target.value)}
                  helperText={error ? "Please input elementary school" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  High School:
                </Typography>
                <TextField
                  label="high School"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.educationalBackground.hs}
                  onChange={(e) => handleChange("hs", e.target.value)}
                  helperText={error ? "Please input High school" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  College:
                </Typography>
                <TextField
                  label="college"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.educationalBackground.college}
                  onChange={(e) => handleChange("college", e.target.value)}
                  helperText={error ? "Please input college school" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Other Courses:
                </Typography>
                <TextField
                  label="Other Courses"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.educationalBackground.otherCourses}
                  onChange={(e) => handleChange("otherCourses", e.target.value)}
                  helperText={error ? "Please input other courses" : ""}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack direction={"column"} spacing={1}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Year of passing:
                </Typography>
                <TextField
                  label="Year of passing"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.educationalBackground.elemYearOfPassing}
                  onChange={(e) =>
                    handleChange("elemYearOfPassing", e.target.value)
                  }
                  helperText={error ? "This field is required" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Year of passing:
                </Typography>
                <TextField
                  label="year of passing"
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={data.educationalBackground.hsYearOfPassing}
                  onChange={(e) =>
                    handleChange("hsYearOfPassing", e.target.value)
                  }
                  helperText={error ? "This field is required" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Year of passing:
                </Typography>
                <TextField
                  label="year of passing"
                  type="text"
                  style={{ width: "100%" }}
                  value={data.educationalBackground.collegeYearOfPassing}
                  onChange={(e) =>
                    handleChange("collegeYearOfPassing", e.target.value)
                  }
                  error={error}
                  helperText={error ? "This field is required" : ""}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ display: "flex", alignItems: "center" }}
                spacing={2}
              >
                <Typography sx={{ width: "150px" }} variant="div">
                  Year of passing:
                </Typography>
                <TextField
                  label="year of passing"
                  type="text"
                  style={{ width: "100%" }}
                  value={data.educationalBackground.otherCoursesYearOfPassing}
                  onChange={(e) =>
                    handleChange("otherCoursesYearOfPassing", e.target.value)
                  }
                  error={error}
                  helperText={error ? "This field is required" : ""}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default EducationalBackground;
