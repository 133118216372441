import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Stack,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AttendanceWidget from "../admin/widgets/AttendanceWidget";
import EmployeeWidget from "../admin/widgets/EmployeeWidget";
import GenderWidget from "../admin/widgets/GenderWidget";
import EmployeeStatusWidget from "../admin/widgets/EmployeeStatusWidget";
import ClientWidget from "../admin/widgets/ClientWidget";
import BirthdayWidget from "../admin/widgets/BirthdayWidget";
import ExpiredWidget from "../admin/widgets/ExpiredWidget";
import LeaveSummaryWidget from "../admin/widgets/LeaveSummaryWidget";
import LoanWidget from "../admin/widgets/LoanWidget";
import "./dashboard.css";

export default function Dashboard() {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("0");

  const handleSelect = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Paper elevation={3} sx={{ p: 2, overflowX: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            value={selectedValue}
            onChange={handleSelect}
            sx={{ width: "100%" }}
          >
            <MenuItem value="0" disabled>
              All
            </MenuItem>
            <MenuItem value="1">January</MenuItem>
            <MenuItem value="2">February</MenuItem>
            <MenuItem value="3">March</MenuItem>
            <MenuItem value="4">April</MenuItem>
            <MenuItem value="5">May</MenuItem>
            <MenuItem value="6">June</MenuItem>
            <MenuItem value="7">July</MenuItem>
            <MenuItem value="8">August</MenuItem>
            <MenuItem value="9">September</MenuItem>
            <MenuItem value="10">October</MenuItem>
            <MenuItem value="11">November</MenuItem>
            <MenuItem value="12">December</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <EmployeeWidget />
        </Grid>
        <Grid item xs={12} md={9}>
          <AttendanceWidget />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
        <Grid item xs={12} md={3}>
          <GenderWidget />
        </Grid>
        <Grid item xs={12} md={9}>
          <EmployeeStatusWidget />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
        <Grid item xs={12} md={3}>
          <ExpiredWidget />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <BirthdayWidget />
                <ClientWidget />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <LeaveSummaryWidget />
                <LoanWidget />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
