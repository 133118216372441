import { Paper, Typography } from "@mui/material";
import React from "react";

function BirthdayWidget() {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        maxHeight: "300px",
        height: "209px",
        overflow: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textAlign: "center",
          backgroundColor: "#E1F7F5",
          color: "black",
        }}
      >
        Birthday
      </Typography>
    </Paper>
  );
}

export default BirthdayWidget;
