import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ResponsiveList from "../../../components/list/ResponsiveList";

function LeaveSummaryWidget() {
  const categories = [
    { title: "Sick", value: 5 },
    { title: "Maternity", value: 3 },
    { title: "Vacation", value: 3 },
    { title: "Special", value: 3 },
  ];
  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Stack spacing={2}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            backgroundColor: "#E1F7F5",
            color: "black",
          }}
        >
          Leave Summary
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <ResponsiveList categories={categories} />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default LeaveSummaryWidget;
