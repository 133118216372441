import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ResponsiveList from "../../../components/list/ResponsiveList";

function EmployeeStatusWidget() {
  const categories = [
    { title: "REGULAR", value: 5 },
    { title: "CASUAL", value: 3 },
    { title: "CONTRACTUAL", value: 3 },
    { title: "OJT", value: 3 },
    { title: "PART TIME", value: 3 },
  ];
  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Stack spacing={2}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            backgroundColor: "#E1F7F5",
            color: "black",
          }}
        >
          EMPLOYEE STATUS
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <ResponsiveList categories={categories} />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default EmployeeStatusWidget;
