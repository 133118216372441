import { Box, Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ModalData } from "../../../components/modal/Modal";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";
import CsvImporterComponent from "../../../components/csvImport/CSVImport";
import { useGetUsersQuery } from "../../../app/api/users/usersApiSlice";
import useAuth from "../../../hooks/useAuth";
import dayjs from "dayjs";
const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 700, // Update the font weight of table headers
            backgroundColor: "#f5f5f5", // Optional: Add a background color for better visibility
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h6: {
            fontWeight: 700, // Update the font weight of the table title
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: "pink",
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            "&.Mui-active": {
              fontWeight: "bold", // Make sorted header bold
            },
            "& .MuiTableSortLabel-icon": {
              fontWeight: "bold", // Make sort icon bold
            },
          },
        },
      },
    },
  });
function EmployeeData() {
  const { branch_no } = useAuth();
  const [openModal, setOpenModal] = React.useState(false);
  const [bodyContent, setBodyContent] = React.useState("");
  const [modalType, setModalType] = React.useState("");
  const navigate = useNavigate();
  const { data: users, isLoading } = useGetUsersQuery(
    {
      branch_id: branch_no,
      id: "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [employeeData, setEmployeeData] = useState([]);
  const columns = [
    {
      name: "id",
      label: "ID No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "position",
      label: "Position",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastname",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "firstname",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "middlename",
      label: "Middle Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "caddress",
      label: "Complete Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "license_no",
      label: "License No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "expiry_date",
      label: "Expiry Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "assigned_area",
      label: "Area of Assignment",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "original_post",
      label: "Original Post",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "birthday",
      label: "Birthday",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "contact_no",
      label: "Contact No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ width: "200px" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate(`/manage-employees/edit/${tableMeta.rowData[0]}`);
                }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate(`/manage-employees/edit/${tableMeta.rowData[0]}`);
                }}
                sx={{ ml: 2 }}
              >
                Print
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (users && !isLoading) {
      processUsersData(users);
    }
  }, [users, isLoading]);

  const processUsersData = (users) => {
    let allDataArray = [];
    if (Array.isArray(users)) {
      allDataArray = users;
    } else {
      allDataArray = Object.entries(users.entities).map(
        ([key, value]) => value
      );
    }
    setEmployeeData(allDataArray);
  };

  /*const data = [
    { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
    { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
    { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
    {
      name: "James Houston",
      company: "Test Corp",
      city: "Dallas",
      state: "TX",
    },
  ];*/
  const tableData = employeeData.map((data) => {
    return {
      id: data.id,
      status: data.status,
      position: data.position,
      lastname: data.last_name,
      firstname: data.first_name,
      middlename: data.middle_name,
      caddress: data.address,
      license_no: data.licenseNo,
      expiry_date: data.expiryDate
        ? dayjs(data.expiryDate).format("MM/DD/YYYY")
        : "",
      assigned_area: data.assigned_area,
      original_post: data.original_post,
      birthday: data.birthdate
        ? dayjs(data.birthdate).format("MM/DD/YYYY")
        : "",
      contact_no: data.phoneNumber,
    };
  });

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    onRowsDelete: (rowsDeleted) => {
      console.log(rowsDeleted);
    },
    print: false,
    download: true,
    filter: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    setRowProps: (row, dataIndex) => {
      if (dataIndex === 0) {
        return {
          className: "MuiDataTable-FirstRow",
          style: { display: "none !important" },
        };
      }
      return {};
    },
    customToolbar: () => {
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleImportEmployee}
            sx={{ mr: 2 }}
          >
            <PersonAddIcon /> Import Employee
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddEmployee}
          >
            <PersonAddIcon /> Add Employee
          </Button>
        </>
      );
    },
  };
  const handleImportEmployee = () => {
    setBodyContent(<CsvImporterComponent sType="importEmployee" />);
    setModalType("importEmployee");
    setOpenModal(true);
  };
  const handleAddEmployee = () => {
    navigate("/manage-employees/add");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Paper elevation={3} sx={{ p: 2, mt: 2, minHeight: "600px" }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Employee Data"}
            data={tableData}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
        {openModal && (
          <ModalData
            openModal={openModal}
            closeModal={handleCloseModal}
            bodyContent={bodyContent}
            type={modalType}
          />
        )}
      </Paper>
    </>
  );
}

export default EmployeeData;
