import React, { useState, useCallback, useRef } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./importer.css";
import {
  useUploadEmployeeMutation,
  useUploadAttendanceMutation,
} from "../../app/api/users/usersApiSlice";
import { Button, Grid, LinearProgress, Box } from "@mui/material";
import dayjs from "dayjs";
import socketService from "../../app/api/ws/initializeWebSocketService";
import useAuth from "../../hooks/useAuth";

function convertTo24Hour(time12h) {
  let [time, period] = time12h.split(" ");
  let [hour, minute] = time.split(":");
  hour = parseInt(hour);

  if (period === "PM" && hour !== 12) {
    hour += 12;
  } else if (period === "AM" && hour === 12) {
    hour = 0;
  }

  return `${hour.toString().padStart(2, "0")}:${minute}`;
}

// Function to pad single-digit hour with leading zero
function formatTime(time) {
  const [hour, minute] = time.split(":");
  return `${hour.padStart(2, "0")}:${minute}`;
}
const CsvImporterComponent = ({ sType }) => {
  const [uploadEmployee] = useUploadEmployeeMutation();
  const [uploadAttendance] = useUploadAttendanceMutation();
  const [resetImporter, setResetImporter] = useState(false);
  const [countImported, setCountImported] = useState(0);
  const [failedUploads, setFailedUploads] = useState(0);
  const [loading, setLoading] = useState(false);

  const importEmployee = async (row) => {
    try {
      const formData = new FormData();
      formData.append("uploadData", JSON.stringify(row));

      await uploadEmployee({ formData: formData, type: "employee" }).unwrap();
    } catch (error) {
      toast.error("Error adding employee: " + error.message);
      //throw error;
    }
  };

  const importAttendance = async (row) => {
    try {
      const formData = new FormData();
      formData.append("uploadData", JSON.stringify(row));
      formData.append("added_by", 1);
      formData.append("branch_id", 1);

      await uploadAttendance({
        formData: formData,
        type: "attendance",
      }).unwrap();
    } catch (error) {
      toast.error("Error adding employee: " + error.message);
      //throw error;
    }
  };

  const importPayroll = async (row) => {
    try {
      const formData = new FormData();
      formData.append("uploadData", JSON.stringify(row));
      await uploadEmployee({ formData: formData, type: "payroll" }).unwrap();
    } catch (error) {
      toast.error("Error adding employee: " + error.message);
      //throw error;
    }
  };

  const prepMyAppForIncomingData = (fields, rows) => {
    console.log("Preparing app for incoming data...", rows);
  };

  const showMyAppToastNotification = () => {
    toast("🦄 Wow so easy!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const goToMyAppNextPage = () => {
    setResetImporter(true);
  };

  const handleStart = useCallback(({ fields }) => {
    prepMyAppForIncomingData(fields);
  }, []);

  const handleComplete = useCallback(() => {
    showMyAppToastNotification();
    socketService.sendMessage({ type: "refresh" });
    setResetImporter(true);
  }, []);

  const handleClose = useCallback(() => {
    goToMyAppNextPage();
  }, []);

  const handleCancel = () => {
    setLoading(false);
  };

  let contentImport = "";
  switch (sType) {
    case "importEmployee":
      contentImport = (
        <>
          <ImporterField name="firstName" label="First name" />
          <ImporterField name="middleName" label="Middle name" optional />
          <ImporterField name="lastName" label="Last name" />
          <ImporterField name="address" label="Address" optional />
          <ImporterField name="nationality" label="Nationality" optional />
          <ImporterField name="fathersName" label="Fathers Name" optional />
          <ImporterField name="mothersName" label="Mothers Name" optional />
          <ImporterField name="mobileNo" label="Contact Number" optional />
          <ImporterField name="height" label="Height" optional />
          <ImporterField name="dateOfBirth" label="Birthday" optional />
          <ImporterField name="gender" label="Gender" optional />
          <ImporterField name="religion" label="Religion" optional />
          <ImporterField name="languages" label="Languages" optional />
          <ImporterField name="fbAccount" label="Facebook Account" optional />
          <ImporterField name="email" label="Email" optional />
          <ImporterField name="licenseNo" label="License No." optional />
          <ImporterField name="expiryDate" label="Expiry Date" optional />
          <ImporterField name="sss" label="SSS" optional />
          <ImporterField name="pagibig" label="Pagibig" optional />
          <ImporterField name="philhealth" label="Philhealth" optional />
          <ImporterField name="tin" label="Tin Number" optional />
          <ImporterField
            name="areaOfAssignment"
            label="Area of Assignment"
            optional
          />
          <ImporterField name="status" label="Status" optional />
          <ImporterField name="dateHired" label="Date Hired" optional />
        </>
      );
      break;
    case "importAttendance":
      contentImport = (
        <>
          <ImporterField name="id" label="id" optional />
          <ImporterField name="firstName" label="First name" optional />
          <ImporterField name="middleName" label="Middle name" optional />
          <ImporterField name="lastName" label="Last name" optional />
          <ImporterField name="date" label="Date" />
          <ImporterField name="arrivalTime" label="Arrival Time" />
          <ImporterField name="departureTime" label="Departure Time" />
          <ImporterField name="type" label="Type" />
          <ImporterField name="totalHours" label="Total Hours" />
        </>
      );
      break;
    case "importPayroll":
      contentImport = (
        <>
          <ImporterField name="user_id" label="user_id" />
          <ImporterField name="firstName" label="First name" optional />
          <ImporterField name="middleName" label="Middle name" optional />
          <ImporterField name="lastName" label="Last name" optional />
          <ImporterField name="Period" label="Period" />
          <ImporterField name="Month" label="Month" />
          <ImporterField name="Year" label="Year" />
          <ImporterField name="Branch_no" label="Branch Number" />
          <ImporterField name="Number_of_days" label="Number Of Days" />
          <ImporterField name="salary_rate" label="Salary Rate" />
          <ImporterField name="sss_rate" label="SSS Rate" />
          <ImporterField name="philhealth_rate" label="Philhealth Rate" />
          <ImporterField name="total_salary" label="Total Salary" />
          <ImporterField name="gross_salary" label="Gross_salary" />
          <ImporterField name="overtime" label="Overtime" optional />
          <ImporterField name="tardy" label="Tardy" optional />
          <ImporterField name="absent" label="Absent" optional />
          <ImporterField name="leave" label="leave" optional />
          <ImporterField name="advance" label="Advance" optional />
          <ImporterField name="bonus" label="Bonus" optional />
          <ImporterField name="allowance" label="Allowance" optional />
          <ImporterField name="nd" label="ND" optional />
          <ImporterField name="rd" label="RD" optional />
          <ImporterField name="reimbursement" label="Reimbursement" optional />
          <ImporterField name="cal" label="CAL" optional />
          <ImporterField name="par" label="Paraphernalia" optional />
          <ImporterField name="rice" label="Rice" optional />
          <ImporterField name="v1" label="V1" optional />
          <ImporterField name="v2" label="V2" optional />
          <ImporterField name="license" label="License" optional />
          <ImporterField name="cb" label="CB" optional />
          <ImporterField name="phic" label="PHIC" optional />
          <ImporterField name="mplpagc" label="MPLGPAGC" optional />
          <ImporterField name="call_other" label="Call Other" optional />
          <ImporterField name="ut" label="Under Time" optional />
          <ImporterField name="total_loan" label="Total Loan" optional />
          <ImporterField
            name="total_deduction"
            label="Total Deduction"
            optional
          />
        </>
      );
      break;
    default:
      break;
  }
  const { user, branch_no } = useAuth();
  return (
    <div>
      {
        /*resetImporter ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <h1>
              Successfully
              <br />
              Imported <span style={{ color: "red" }}>
                {countImported}
              </span>{" "}
              employees
            </h1>
            <h1>
              Failed to import{" "}
              <span style={{ color: "red" }}>{failedUploads}</span> employees
            </h1>
            <Button
              onClick={() => setResetImporter(false)}
              variant="outlined"
              color="primary"
            >
              Upload New CSV
            </Button>
          </Grid>
        </Grid>
      ) : (*/
        <Importer
          dataHandler={async (rows, { startIndex }) => {
            let uploadData = [];
            // required, may be called several times
            // receives a list of parsed objects based on defined fields and user column mapping;
            // (if this callback returns a promise, the widget will wait for it before parsing more data)
            for (let row of rows) {
              if (sType === "importEmployee") {
                let expiryDate =
                  row.expiryDate && dayjs(row.expiryDate).isValid()
                    ? dayjs(row.expiryDate).format("YYYY-MM-DD")
                    : "";
                let dob =
                  row.dateOfBirth && dayjs(row.dateOfBirth).isValid()
                    ? dayjs(row.dateOfBirth).format("YYYY-MM-DD")
                    : "";
                uploadData.push({
                  firstname: row.firstName,
                  middlename: row.middleName,
                  lastname: row.lastName,
                  address: row.address,
                  nationality: row.nationality,
                  fathersname: row.fathersName,
                  mothersname: row.mothersName,
                  mobileno: row.mobileNo,
                  height: row.height,
                  dateofbirth: dob,
                  gender: row.gender,
                  religion: row.religion,
                  languages: row.languages,
                  fbaccount: row.fbAccount,
                  email: row.email,
                  licenseno: row.licenseNo,
                  expirydate: expiryDate,
                  benifits: {
                    sss: row.sss,
                    pagibig: row.pagibig,
                    philhealth: row.philhealth,
                    tin: row.tin,
                  },
                  aor: row.areaOfAssignment,
                  work_status:
                    row.status === "ACTIVE"
                      ? 1
                      : row.status === "RESIGN"
                      ? 2
                      : 0,
                  date_hired: dayjs(row.dateHired).format(),
                  added_by: 1,
                  branch_id: branch_no,
                });
              } else if (sType === "importAttendance") {
                uploadData.push({
                  id: row.id,
                  firstname: row.firstName,
                  middlename: row.middleName,
                  lastname: row.lastName,
                  date: dayjs(row.date).format(),
                  arrivalTime: formatTime(row.arrivalTime),
                  departureTime: convertTo24Hour(row.departureTime),
                  total_hours: row.totalHours,
                  type: row.type,
                  added_by: 1,
                  branch_id: branch_no,
                });
              }
            }
            if (sType === "importEmployee") {
              await importEmployee(uploadData);
            } else if (sType === "importAttendance") {
              await importAttendance(uploadData);
            } else if (sType === "importPayroll") {
              await importPayroll(uploadData);
            }
          }}
          defaultNoHeader={false}
          restartable={true}
          onStart={handleStart}
          onComplete={handleComplete}
          onClose={handleClose}
        >
          {contentImport}
          <ToastContainer />
        </Importer>

        /*)*/
      }
    </div>
  );
};

export default CsvImporterComponent;
