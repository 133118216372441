import { Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import UploadButton from "../../../../components/attachments/UploadFileData";

function BenefitsInformaton({
  setDocumentUploaded,
  benefitsInformation,
  setBenefitsInformation,
}) {
  const [error, setError] = useState(false);

  const handleChange = (field, value) => {
    setBenefitsInformation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Grid container spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
              BENEFITS INFORMATION
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Stack spacing={1} sx={{ mb: 2 }}>
              <Typography
                variant="div"
                sx={{ fontWeight: 700, textAlign: "center" }}
              >
                SSS Number
              </Typography>
              <TextField
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={benefitsInformation.sss}
                onChange={(e) => handleChange("sss", e.target.value)}
                variant="standard"
                helperText={error ? "Please input SSS Number" : ""}
              />
            </Stack>
            <UploadButton
              title={"SSS Payment Slip"}
              keyValue={"sss"}
              iconHidden={true}
              setDocumentUploaded={setDocumentUploaded}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Stack spacing={1} sx={{ mb: 2 }}>
              <Typography
                variant="div"
                sx={{ fontWeight: 700, textAlign: "center" }}
              >
                PAG-IBIG Number
              </Typography>
              <TextField
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={benefitsInformation.pagibig}
                onChange={(e) => handleChange("pagibig", e.target.value)}
                variant="standard"
                helperText={error ? "Please input PAG-IBIG Number" : ""}
              />
            </Stack>
            <UploadButton
              title={"PAG-IBIG Payment Slip"}
              keyValue={"pagibig"}
              iconHidden={true}
              setDocumentUploaded={setDocumentUploaded}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Stack spacing={1} sx={{ mb: 2 }}>
              <Typography
                variant="div"
                sx={{ fontWeight: 700, textAlign: "center" }}
              >
                PHILHEALTH Number
              </Typography>
              <TextField
                label=""
                type="text"
                style={{ width: "100%" }}
                error={error}
                value={benefitsInformation.philhealth}
                onChange={(e) => handleChange("philhealth", e.target.value)}
                variant="standard"
                helperText={error ? "Please input PHILHEALTH Number" : ""}
              />
            </Stack>

            <UploadButton
              title={"PHILHEALTH Payment Slip"}
              keyValue={"philhealth"}
              iconHidden={true}
              setDocumentUploaded={setDocumentUploaded}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default BenefitsInformaton;
