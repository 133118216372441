import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    employeeWidget: builder.query({
      query: () => "/dashboard/employee",
    }),
  }),
  overrideExisting: true,
  reducerPath: "dashboardApi",
  entityTypes: ["Dashboard"],
});

export const { useEmployeeWidgetQuery } = dashboardApiSlice;
