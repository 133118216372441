import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const postsAdapter = createEntityAdapter();

const initialState = postsAdapter.getInitialState();

export const frontDtrApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAttendance: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/dtr",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
        { type: "DTR", id: "DTR" },
      ],
    }),
    updateAttendance: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/dtr/update",
          method: "PUT",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
        { type: "DTR", id: "DTR" },
      ],
    }),
  }),
  adapter: postsAdapter,
  initialState,
  reducerPath: "frontDtrApi",
});

export const { useAddAttendanceMutation, useUpdateAttendanceMutation } =
  frontDtrApiSlice;
export const { reducer } = frontDtrApiSlice;
export const { selectAll: selectAllAttendance } = postsAdapter.getSelectors(
  (state) => state.frontDtrApi
);
