import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  Button,
  IconButton,
  Paper,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { useState } from "react";

function WorkExperience({ workExperiences, setWorkExperiences }) {
  const [error, setError] = useState(false);

  const handleAddWorkExperience = () => {
    setWorkExperiences([
      ...workExperiences,
      { companyName: "", position: "", workPeriod: "" },
    ]);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedWorkExperiences = workExperiences.map((workExperience, i) =>
      i === index ? { ...workExperience, [name]: value } : workExperience
    );
    setWorkExperiences(updatedWorkExperiences);
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
          WORK EXPERIENCE
        </Typography>
        <Grid container spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Typography
              variant="div"
              sx={{ fontWeight: 700, textAlign: "center" }}
            >
              Company Name
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Typography
              variant="div"
              sx={{ fontWeight: 700, textAlign: "center" }}
            >
              Position
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Typography
              variant="div"
              sx={{ fontWeight: 700, textAlign: "center" }}
            >
              Work Period
            </Typography>
          </Grid>
        </Grid>
        {workExperiences.map((workExperience, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            <Grid container spacing={2} className="work-experience">
              <Grid item xs={12} md={4}>
                <TextField
                  label="Company Name"
                  name="companyName"
                  type="text"
                  style={{ width: "100%" }}
                  value={workExperience.companyName}
                  onChange={(event) => handleInputChange(index, event)}
                  error={error}
                  helperText={error ? "Please input company name" : ""}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Position"
                  name="position"
                  type="text"
                  style={{ width: "100%" }}
                  value={workExperience.position}
                  onChange={(event) => handleInputChange(index, event)}
                  error={error}
                  helperText={error ? "Please input position" : ""}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Work Period"
                  name="workPeriod"
                  type="text"
                  style={{ width: "100%" }}
                  value={workExperience.workPeriod}
                  onChange={(event) => handleInputChange(index, event)}
                  error={error}
                  helperText={error ? "Please input work period" : ""}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddWorkExperience}
          sx={{ mt: 2 }}
        >
          <AddCircleOutlineIcon />
          Add Work Experience
        </Button>
      </Box>
    </Paper>
  );
}

export default WorkExperience;
