import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const postsAdapter = createEntityAdapter();

const initialState = postsAdapter.getInitialState();

export const payrollApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateNewPayroll: builder.mutation({
      query: ({ formData }) => ({
        url: `/payroll`,
        method: "POST",
        body: formData,
      }),
    }),
    generateManualPayroll: builder.mutation({
      query: ({ formData }) => ({
        url: `/payroll/generateManualPayroll`,
        method: "POST",
        body: formData,
      }),
    }),
    getPayroll: builder.query({
      query: ({ month, year, period, branch_id, user_id, isSuperAdmin }) => {
        // Ensure dates are in the correct format
        return `/payroll/generatePayroll?month=${month}&year=${year}&period=${period}&branch_id=${branch_id}&user_id=${user_id}`;
      },
      //query: (id) => `/employee-dtr/${id}`,
      //providesTags: [{ type: "DTR", id: "DTR" }],
    }),
    getCheckPayroll: builder.query({
      query: ({ branch_id, isSuperAdmin, year }) => {
        // Ensure dates are in the correct format
        return `/payroll/checkPayroll?branch_id=${branch_id}&isSuperAdmin=${isSuperAdmin}&year=${year}`;
      },
      //query: (id) => `/employee-dtr/${id}`,
      //providesTags: [{ type: "DTR", id: "DTR" }],
    }),
  }),
});

export const {
  useGenerateNewPayrollMutation,
  useGetPayrollQuery,
  useGetCheckPayrollQuery,
  useGenerateManualPayrollMutation,
} = payrollApiSlice;
