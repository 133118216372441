import { Button, Paper, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ModalData } from "../../../components/modal/Modal";
import Camera from "../../../components/camera/Camera";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { Navigate, useNavigate } from "react-router-dom";
import { useGetCheckPayrollQuery } from "../../../app/api/payroll/generatePayroll";
import useAuth from "../../../hooks/useAuth";
import CustomizedMenus from "../../../components/buttons/CustomizeMenu";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CsvImporterComponent from "../../../components/csvImport/CSVImport";

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 700, // Update the font weight of table headers
            backgroundColor: "#f5f5f5", // Optional: Add a background color for better visibility
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h6: {
            fontWeight: 700, // Update the font weight of the table title
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: "pink",
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            "&.Mui-active": {
              fontWeight: "bold", // Make sorted header bold
            },
            "& .MuiTableSortLabel-icon": {
              fontWeight: "bold", // Make sort icon bold
            },
          },
        },
      },
    },
  });

const optionMonth = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];
function PayrollData() {
  const [openModal, setOpenModal] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
  const [bodyContent, setBodyContent] = React.useState("");
  const [modalType, setModalType] = React.useState("");
  const navigate = useNavigate();
  const { branch_no } = useAuth();
  const [filterDTR, setFilterDTR] = useState({
    //month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    period: 1,
    branch_id: branch_no,
  });
  const handleCreatePayroll = () => {
    navigate("/manage-payroll/create-payroll");
  };
  const handleImportPayroll = () => {
    setBodyContent(<CsvImporterComponent sType="importPayroll" />);
    setModalType("importPayroll");
    setOpenModal(true);
  };
  const custMenuOption = [
    {
      label: "Create Payroll",
      icon: <GroupAddIcon />,
      action: handleCreatePayroll,
    },
    {
      label: "Create 13th Month Pay",
      icon: <GroupAddIcon />,
      action: () => {
        navigate("/manage-payroll/create-13th-month");
      },
    },
    {
      label: "Import Payroll",
      icon: <GroupAddIcon />,
      action: () => {
        handleImportPayroll();
      },
    },
    {
      label: "Download Payroll Template",
      icon: <GroupAddIcon />,
      action: () => {
        window.open("/csv-template/payroll-format.csv", "_blank");
      },
    },
  ];

  const [payrollData, setPayrollData] = useState([]);

  const { data: checkPayrollData, isLoading: checkPayrollLoading } =
    useGetCheckPayrollQuery(
      {
        branch_id: filterDTR.branch_id,
        year: filterDTR.year,
        isSuperAdmin: false,
      },
      {
        refetchOnMountOrArgChange: true,
        //pollingInterval: 1000,
      }
    );

  useEffect(() => {
    if (checkPayrollData) {
      setPayrollData(checkPayrollData);
    }
  }, [checkPayrollData]);

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setFilterDTR((prev) => ({ ...prev, month: selectedMonth }));
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setFilterDTR((prev) => ({ ...prev, year: selectedYear }));
  };

  const handlePeriodChange = (event) => {
    const selectedPeriod = event.target.value;
    setFilterDTR((prev) => ({ ...prev, period: selectedPeriod }));
  };

  const currentYear = new Date().getFullYear();

  const generateYearOptions = (startYear, numberOfYears) => {
    const yearOptions = [];
    for (let i = 0; i < numberOfYears; i++) {
      yearOptions.push(startYear + i);
    }
    return yearOptions;
  };
  const yearOptions = generateYearOptions(currentYear, 5);

  const columns = [
    {
      name: "monthData",
      label: "Month",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "employee",
      label: "Number of Payroll",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (selectedRows, tableMeta, updateValue) => {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AutoGraphIcon />}
                onClick={() => {
                  const data = tableMeta.tableData[tableMeta.rowIndex];
                  navigate(
                    `/manage-payroll/view-payslip?month=${data.month}&year=${data.year}&period=${data.period}&branch_id=${filterDTR.branch_id}`
                  );
                }}
              >
                View Payslip
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AutoGraphIcon />}
                sx={{ ml: 2 }}
                onClick={() => {
                  const data = tableMeta.tableData[tableMeta.rowIndex];
                  navigate(
                    `/manage-payroll/view-payroll?month=${data.month}&year=${data.year}&period=${data.period}&branch_id=${filterDTR.branch_id}`
                  );
                }}
              >
                View Payroll
              </Button>

              <Button
                variant="contained"
                color="primary"
                startIcon={<AutoGraphIcon />}
                sx={{ ml: 2 }}
                onClick={() => {
                  const data = tableMeta.tableData[tableMeta.rowIndex];
                  navigate(
                    `/manage-payroll/edit-payroll?month=${data.month}&year=${data.year}&period=${data.period}&branch_id=${filterDTR.branch_id}`
                  );
                }}
              >
                Edit Payroll
              </Button>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    responsive: "standard",
    onRowsDelete: (rowsDeleted) => {
      console.log(rowsDeleted);
    },
    print: false,
    download: true,
    filter: false,
    customToolbar: () => {
      return (
        <>
          <Select
            sx={{ height: "37px", mr: 2 }}
            value={filterDTR.year}
            onChange={handleYearChange}
            displayEmpty
            inputProps={{ "aria-label": "Select Year" }}
          >
            <MenuItem value="" disabled>
              Select Year
            </MenuItem>
            {yearOptions.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          <CustomizedMenus options={custMenuOption} />
        </>
      );
    },
  };
  const tabledata = payrollData
    .map((data) => {
      let monthParse = parseInt(data.month, 10);

      let month = optionMonth.find((month) => month.value === monthParse);

      if (!month) {
        console.warn(`Month with value ${monthParse} not found in optionMonth`);
        month = { value: monthParse, label: "Unknown" }; // or handle it as you see fit
      }
      let periodData = data.period === 1 ? "1st - 15th" : "16th - 30th";
      return {
        month: monthParse,
        year: data.year,
        period: data.period,
        // periodData: ,
        monthData: `${month.label} ${periodData} ${data.year}`,
        employee: `${data.countExisting} Employees Payroll`,
      };
    })
    .sort((a, b) => a.month - b.month)
    .sort((a, b) => a.period - b.period);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Paper elevation={3} sx={{ p: 2, mt: 2, minHeight: "600px" }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`Payroll for ${filterDTR.year}`}
            data={tabledata}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
        {/*openModal && (
          <ModalData
            openModal={openModal}
            closeModal={handleCloseModal}
            bodyContent={
              <Camera onClose={handleCloseModal} onConfirm={setImageData} />
            }
            type={"takePhoto"}
          />
        )*/}
        {openModal && (
          <ModalData
            openModal={openModal}
            closeModal={handleCloseModal}
            bodyContent={bodyContent}
            type={modalType}
          />
        )}
      </Paper>
    </>
  );
}

export default PayrollData;
