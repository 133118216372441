import { Box } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <Box sx={{ textAlign: "center", fontWeight: 700, mb: 2, mt: 2 }}>
      Developed by DIS TPD
    </Box>
  );
}

export default Footer;
