import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmployeeAdd from "./EmployeeAdd";
import EmployeeData from "./EmployeeData";
import ApplicationForm from "./applicationForms/ApplicationForm";

function Employee() {
  const { id, type } = useParams();
  const navigate = useNavigate();

  let content = null;
  switch (type) {
    case "add":
      content = <EmployeeAdd />;
      break;
    case "edit":
      content = <ApplicationForm />;
      break;
    default:
      content = <EmployeeData />;
      break;
  }
  return content;
}

export default Employee;
