import { Box, Button, Grid } from '@mui/material'
import React,{ useRef,useState,useCallback  }  from 'react'
import Webcam from 'react-webcam'

const Camera = ({onClose,onConfirm}) => {
    // create a capture function
    const webcamRef = useRef('');
    const [imgSrc, setImgSrc] = useState('');

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc || '');
    }, [webcamRef]);

    const retake = () => {
        setImgSrc('');
    };

    const handleSubmit = ()=>{
        onConfirm(imgSrc);
        onClose();
    }

  return (
    <div>
        <Grid item xs={12} alignItems="center" justifyContent="center">
            <Box textAlign={"center"}>
            {imgSrc ? (
                <img src={imgSrc} alt="webcam" name="userImage" />
            ) : (
                <Webcam height={400} width={400} ref={webcamRef} style={{ marginTop: '-80px' }}  />
            )}
            <div className="btn-container">
                {imgSrc ? (
                <Button variant="contained" color="primary" onClick={retake}>Retake photo</Button>
                ) : (
                <Button variant="contained" color="primary" className='btnCaptureImg' onClick={capture}>Capture photo</Button>
                )}
            </div>
            </Box>
        </Grid>
        <Grid item xs={12} textAlign={'right'}>
            <Button variant="contained" color="success" onClick={handleSubmit}>Confirm</Button>
            <Button variant='contained' color='error' onClick = {()=>onClose()} sx={{ marginLeft: 2 }}>Close</Button>
        </Grid>
    </div>
  )
}

export default Camera
