import React from "react";
import PersonalBackground from "./PersonalBackground";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import "./ApplicationForm.css";

function ApplicationForm() {
  let content = null;
  content = (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant="h6">PICHEL AGENCY</Typography>
        <Typography variant="h7">MANAGEMENT</Typography>
        <Typography variant="h7">Accounting & Finance</Typography>
        <Typography variant="h5">EMPLOYMENT APPLICATION FORM</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} spacing={2}>
            <Typography>DATE OF APPLICATION:</Typography>
            <Typography>___________________________</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Typography>POSITION APPLIED FOR:</Typography>
            <Typography>___________________________</Typography>
            <Typography>___________________________</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Typography></Typography>
            <Typography>First Choice</Typography>
            <Typography>Second Choice</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Typography>EXPECTED SALARY: </Typography>
            <Typography>___________________________</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} md={2}>
        <Stack direction={"column"}>
          <Avatar
            variant="rounded"
            sx={{
              width: "192px",
              height: "192px",
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={12}>
        <PersonalBackground />
      </Grid>
    </Grid>
  );
  return content;
}

export default ApplicationForm;
