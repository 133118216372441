import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AttendanceData from "./AttendanceData";
import EmployeeDTR from "./dtr/EmployeeDTR";
import EmployeeDTRAdd from "./dtr/EmployeeFormAdd";

function Attendance() {
  const { id, type, year, month, period } = useParams();
  const navigate = useNavigate();

  let content = null;
  switch (type) {
    case "edit":
      navigate("/manage-employee/edit/" + id);
      break;
    case "add":
      content = <EmployeeDTRAdd type={type} />;
      break;
    case "EmployeeDTR":
      content = (
        <EmployeeDTR
          user_id={id}
          type={type}
          year={year}
          month={month}
          period={period}
        />
      );
      break;
    default:
      content = <AttendanceData />;
      break;
  }
  return content;
}

export default Attendance;
