import React, { useEffect } from "react";
import {
  useGetEmployeeDTRQuery,
  useGetListEmployeeQuery,
  useAddNewDTRMutation,
} from "../../../../app/api/users/usersApiSlice";
import {
  Grid,
  Paper,
  Stack,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Divider,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import "./employeeDTR.css";
import IconMenu from "../../../../components/buttons/ButtonList";
import { selectCurrentUserId } from "../../../../app/api/auth/authSlice";
import { useSelector } from "react-redux";
import useAuth from "../../../../hooks/useAuth";

function convertTo12Hour(time24) {
  // Check if the time already contains "AM" or "PM"
  if (
    time24.toUpperCase().includes("AM") ||
    time24.toUpperCase().includes("PM")
  ) {
    return time24.trim(); // Return the time as is if it already has AM or PM
  }

  const [hours, minutes] = time24.split(":");
  const hours24 = parseInt(hours, 10);
  const period = hours24 >= 12 ? "PM" : "AM";
  const hours12 = hours24 % 12 || 12; // Convert 0 to 12 for midnight case
  return `${hours12}:${minutes} ${period}`;
}

const months = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const rows = Array.from({ length: 32 }, (_, index) => index + 1);

// Helper function to parse time in HH:MM format
const parseTime = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return { hours, minutes };
};

// Helper function to calculate time difference in hours
const calculateTimeDifference = (start, end) => {
  if (!start || !end) return 0;

  const { hours: startHours, minutes: startMinutes } = parseTime(start);
  const { hours: endHours, minutes: endMinutes } = parseTime(end);

  const startInMinutes = startHours * 60 + startMinutes;
  const endInMinutes = endHours * 60 + endMinutes;

  return (endInMinutes - startInMinutes) / 60;
};

// Function to calculate total hours for each row
const calculateTotalHours = (record) => {
  const amHours = calculateTimeDifference(
    record.time_in_am,
    record.time_out_am
  );
  const pmHours = calculateTimeDifference(
    record.time_in_pm,
    record.time_out_pm
  );

  const totalHours = amHours + pmHours;
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);

  return { hours, minutes };
};

function EmployeeDTRAdd(data) {
  const [employeeName, setEmployeeName] = React.useState();
  const [dtrMonth, setDtrMonth] = React.useState();
  const [error, setError] = React.useState(false);
  const [dtrRecords, setdtrRecords] = React.useState(
    rows.map(() => ({
      time_in_am: "",
      time_out_am: "",
      time_in_pm: "",
      time_out_pm: "",
      total_hours: 0,
    }))
  );
  const [totalHours, setTotalHours] = React.useState(0);
  const [shouldFetch, setShouldFetch] = React.useState(false);
  const [listOfEmployee, setListOfEmployee] = React.useState(false);
  const [employeeOptions, setEmployeeOption] = React.useState([]);
  const added_by = useSelector(selectCurrentUserId);
  const { branch_no } = useAuth();

  useEffect(() => {
    if (data.type === "add") {
      setListOfEmployee(true);
    } else {
      setShouldFetch(true);
    }
  }, [data.type]);
  const { data: dtrData, isLoading } = useGetEmployeeDTRQuery(
    {
      user_id: data.user_id,
      year: data.year,
      month: data.month,
      period: data.period,
      branch_id: branch_no,
    },
    {
      skip: !shouldFetch,
      refetchOnMountOrArgChange: true,
    }
  );
  const handleInputChange = (index, field, value) => {
    const newRecords = [...dtrRecords];
    newRecords[index][field] = value;
    setdtrRecords(newRecords);
  };

  const { data: employeeData, isLoading: employeeLoading } =
    useGetListEmployeeQuery(
      { branch_id: branch_no },
      {
        skip: !listOfEmployee,
      }
    );

  useEffect(() => {
    if (employeeData && !employeeLoading) {
      let employeeOption = [];
      employeeData.map((data) => {
        employeeOption.push({
          value: data.user_id,
          label:
            data.personal_info.last_name +
            ", " +
            data.personal_info.first_name +
            " " +
            data.personal_info.middle_name,
        });
      });
      employeeOption.sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeOption(employeeOption);
    }
  }, [employeeData, employeeLoading]);

  const totalHoursAndMinutes = dtrRecords.reduce(
    (acc, record) => {
      const { hours, minutes } = calculateTotalHours(record);
      acc.hours += hours;
      acc.minutes += minutes;
      return acc;
    },
    { hours: 0, minutes: 0 }
  );

  // Convert total minutes to hours and minutes
  const additionalHours = Math.floor(totalHoursAndMinutes.minutes / 60);
  totalHoursAndMinutes.hours += additionalHours;
  totalHoursAndMinutes.minutes %= 60;

  const [addNewDTR, { isLoading: addDTRLoading }] = useAddNewDTRMutation();

  const handleSaveDTR = async () => {
    if (!dtrMonth || !employeeName) {
      setError(true);
    } else {
      setError(false);
      const currentYear = dayjs().year();
      const targetMonth = dtrMonth.value - 1;
      const startDate = dayjs(new Date(currentYear, targetMonth, 1));

      const dtrRecordsToSave = [];

      dtrRecords.forEach((record, index) => {
        const dtrDate = startDate.add(index - 1, "day");

        if (record.time_in_am && record.time_out_am) {
          dtrRecordsToSave.push({
            user_id: employeeName,
            date: dtrDate.format("YYYY-MM-DD"),
            arrivalTime: record.time_in_am,
            departureTime: record.time_out_am,
            type: "AM",
            total_hours: calculateTotalHours(record).hours,
            total_minutes: calculateTotalHours(record).minutes,
            added_by: added_by,
            branch_id: branch_no,
          });
        }

        if (record.time_in_pm && record.time_out_pm) {
          dtrRecordsToSave.push({
            user_id: employeeName,
            date: dtrDate.format("YYYY-MM-DD"),
            arrivalTime: record.time_in_pm,
            departureTime: record.time_out_pm,
            total_hours: calculateTotalHours(record).hours,
            total_minutes: calculateTotalHours(record).minutes,
            added_by: added_by,
            branch_id: branch_no,
            type: "PM",
          });
        }
      });

      if (dtrRecordsToSave.length > 0) {
        try {
          const formData = new FormData();
          formData.append("uploadData", JSON.stringify(dtrRecordsToSave));
          let res = await addNewDTR(formData);
          if (res) {
            alert("DTR successfully saved");
          }
        } catch (error) {
          alert("Error saving DTR");
        }
      } else {
        alert("No records to save");
      }
    }
  };

  let content = null;
  content = (
    <>
      <Grid container spacing={1} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
            <IconMenu type={data.type} handleSaveDTR={handleSaveDTR} />
            <Stack spacing={0}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  textAlign: "center",
                  mb: 2,
                }}
                gutterBottom
              >
                DAILY TIME RECORD
              </Typography>
              {data.type === "add" ? (
                <Autocomplete
                  options={employeeOptions}
                  getOptionLabel={(option) => option.label} // Display the label
                  value={employeeName}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setEmployeeName(newValue.value); // Store the value of the selected option
                    } else {
                      setEmployeeName(null); // Handle case when no option is selected
                    }
                  }}
                  disabled={data.type !== "add"}
                  required
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ textAlign: "center", fontWeight: 700 }}
                      fullWidth
                      placeholder="Select Employee"
                      required
                      className="employeeName"
                      error={error && !employeeName}
                      helperText={
                        error && !employeeName
                          ? "employee name is required"
                          : ""
                      }
                    />
                  )}
                />
              ) : (
                <TextField
                  value={employeeName}
                  disabled
                  variant="standard"
                  fullWidth
                />
              )}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  textAlign: "center",
                }}
                gutterBottom
              >
                Name
              </Typography>
              <Stack spacing={1} direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    width: "140px",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  for the month of
                </Typography>
                {data.type === "add" ? (
                  <Autocomplete
                    options={months}
                    getOptionLabel={(option) => option.label}
                    value={dtrMonth}
                    onChange={(event, newValue) => setDtrMonth(newValue)}
                    disabled={data.type !== "add"}
                    className="dtrMonth"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        sx={{ textAlign: "center", fontWeight: 700 }}
                        fullWidth
                        placeholder="Select Month"
                        required
                        error={error && !dtrMonth}
                        helperText={
                          error && !dtrMonth ? "Month is required" : ""
                        }
                      />
                    )}
                  />
                ) : (
                  <TextField
                    value={dtrMonth}
                    disabled={data.type === "add" ? false : true}
                    variant="standard"
                    sx={{ textAlign: "center", fontWeight: 700 }}
                    fullWidth
                  />
                )}
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    width: "330px",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Office Hours (regular days)
                </Typography>
                <TextField
                  value={dtrData?.month}
                  disabled={data.type === "add" ? false : true}
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    width: "240px",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Arrival & Departure
                </Typography>
                <TextField
                  value={dtrData?.month}
                  disabled={data.type === "add" ? false : true}
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    width: "150px",
                    fontSize: "14px",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Saturdays
                </Typography>
                <TextField
                  value={dtrData?.month}
                  disabled={data.type === "add" ? false : true}
                  variant="standard"
                  fullWidth
                />
              </Stack>
              <Stack spacing={0} sx={{ mt: 2 }}>
                <TableContainer>
                  <Table className="tableDTR">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            width: "35px",
                          }}
                        ></TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "center",
                            border: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          AM
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "center",
                            border: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          PM
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        ></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            width: "35px",
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Time In
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Time Out
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Time In
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Time Out
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Hours
                        </TableCell>
                        <TableCell
                          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          Min.
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(
                        (row) =>
                          row !== 32 && (
                            <TableRow key={row} className={`key` + row}>
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  width: "35px",
                                }}
                              >
                                {row}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <TextField
                                  type="time"
                                  value={dtrRecords[row]?.time_in_am || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      row,
                                      "time_in_am",
                                      e.target.value
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <TextField
                                  type="time"
                                  value={dtrRecords[row]?.time_out_am || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      row,
                                      "time_out_am",
                                      e.target.value
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <TextField
                                  type="time"
                                  value={dtrRecords[row]?.time_in_pm || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      row,
                                      "time_in_pm",
                                      e.target.value
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <TextField
                                  type="time"
                                  value={dtrRecords[row]?.time_out_pm || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      row,
                                      "time_out_pm",
                                      e.target.value
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                {calculateTotalHours(dtrRecords[row]).hours}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                {calculateTotalHours(dtrRecords[row]).minutes}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{ textAlign: "right", borderBottom: "none" }}
                          style={{
                            textAlign: "right",
                            right: "10px",
                            position: "relative",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            Total
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1} sx={{ borderBottom: "none" }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {totalHoursAndMinutes.hours}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1} sx={{ borderBottom: "none" }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              display: "flex",
                              color: "black",
                            }}
                          >
                            {totalHoursAndMinutes.minutes}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Stack>
              <Stack>
                <Typography sx={{ mt: 2, fontWeight: 600, fontSize: "14px" }}>
                  <span
                    style={{ display: "inline-flex", marginRight: "123px" }}
                  ></span>
                  I certify on my honor that the above is a true and correct
                  report of the hours of work performed, record of which was
                  made daily at the time of arrival and departure from office.
                </Typography>
                <Box sx={{ mt: 1, mb: 1 }} />
                <TextField
                  value={dtrData?.employee_name}
                  disabled
                  variant="standard"
                  fullWidth
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  (Signature)
                </Typography>
                <Box sx={{ mt: 1, mb: 1 }} />
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                  Verified as to the prescribed office hours
                </Typography>

                <Box sx={{ mt: 1, mb: 1 }} />
                <TextField
                  value={dtrData?.employee_name}
                  disabled
                  variant="standard"
                  fullWidth
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  gutterBottom
                >
                  (Signature)
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
  return content;
}

export default EmployeeDTRAdd;
